import { Close, ErrorOutline, ExpandLess, ExpandMore, Redo } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { useI18n } from '../../../../../application/language/useI18n';
import { ITaskLanguage } from '../../../service/unit/interface/IUnitPractice';
import { CodeContextMenu } from './parts/contextMenu/CodeContextMenu';
import { FileTree } from './parts/fileTree/FileTree';
import { ReviewEditor } from './parts/review/ReviewEditor';
import { CodeEditorDomain } from './store/CodeEditorDomain';
import { CodeMode } from './store/ICodeMode';

export interface ICodeEditorProperties {
  lang: ITaskLanguage;
  isExpand: boolean;
  handleExpand: (isExpand: boolean) => void;
}

export const CodeEditor = observer(({ lang, isExpand, handleExpand }: ICodeEditorProperties) => {
  const { translate } = useI18n();
  const [domain] = useState<CodeEditorDomain>(new CodeEditorDomain());
  const containerRef = useRef(null as any);
  const [editorHeight, setEditorHeight] = useState<number>(0);
  const [headerRef, setHeaderRef] = useState<HTMLDivElement>();

  useEffect(() => {
    if (lang !== null) {
      domain.ui.fileTree.setList(lang.files);
      domain.ui.lang.setValue(lang);
      domain.ui.currentFile.setValue(null as any);
    }
    setEditorHeight(containerRef.current.clientHeight);
  }, [domain.ui.fileTree, domain.ui.lang, lang]);
  return (
    <Box
      ref={containerRef}
      sx={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        height: '100%',
        position: 'relative',
        minHeight: '403px',
      }}
    >
      <Box
        ref={setHeaderRef}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '8px 16px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
          borderTop: '1px solid rgba(255, 255, 255, 0.12)',
        }}
      >
        <Box
          sx={{
            background: 'rgba(0, 0, 0, 0.2)',
            marginRight: '16px',
            borderRadius: '8px',
            display: 'flex',
          }}
        >
          <IconButton sx={{}}>
            <Redo sx={{ transform: ' scale(-1, 1) ' }} />
          </IconButton>
          <Divider orientation="vertical" />

          <IconButton>
            <Redo />
          </IconButton>
        </Box>
        <Button
          color="secondary"
          size="small"
          sx={{ background: 'rgba(0, 0, 0, 0.2)', borderRadius: '8px', textTransform: 'none', marginRight: 'auto' }}
        >
          {translate('phrases.resetChanges')}
        </Button>
        {/* <Button
          color="secondary"
          size="small"
          sx={{ background: 'rgba(0, 0, 0, 0.2)', borderRadius: '8px', textTransform: 'none' }}
        >
          Загрузить проект{' '}
        </Button> */}
        <Tooltip title={!isExpand ? translate('phrases.expand') : translate('phrases.fold')}>
          <IconButton
            onClick={() => {
              handleExpand(!isExpand);
            }}
            sx={{
              marginLeft: '8px',
              alignSelf: 'center',
              height: '24px',
              width: '24px',
              background: '#f5f5f5',
              borderRadius: '4px',
              color: 'black',
              '&:hover': {
                background: '#1976D2',
                color: 'white',
              },
            }}
          >
            {!isExpand && <ExpandLess />}
            {isExpand && <ExpandMore />}
          </IconButton>
        </Tooltip>
      </Box>{' '}
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Box
          sx={{
            minWidth: '218px',
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            maxWidth: 'fit-content',
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
            marginBottom: '5px',
            height: isExpand ? `calc(100% - ${headerRef?.clientHeight}px)` : 'auto',
          }}
        >
          <Typography sx={{ padding: '4px 8px' }}>{translate('phrases.project')}</Typography>
          <FileTree
            openContextMenu={domain.openContextMenu}
            fileTree={domain.ui.fileTree.list}
            pickingFile={domain.pickingFile}
            isInputFileOpen={domain.ui.isInputFileOpen}
            isInputFolderOpen={domain.ui.isInputFolderOpen}
            isInputRenameOpen={domain.ui.isInputRenameOpen}
            creatingNewFolder={domain.creatingNewFolder}
            creatingNewFile={domain.creatingNewFile}
            currentFile={domain.ui.currentFile.value}
            toggleQuestion={domain.toggleQuestion}
            rename={domain.rename}
          />
          {domain.ui.currentFile.value !== null && !domain.ui.currentFile.value.hasOwnProperty('children') && (
            <Box
              onClick={(event) => {
                event.preventDefault();
                domain.toggleQuestion(domain.ui.currentFile.value);
              }}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                gap: '9.5px',
                fontSize: '16px',
                color: 'rgba(25, 118, 210, 1)',
                marginLeft: '16px',
                marginBottom: '16px',
              }}
            >
              {domain.ui.currentFile.value.isQuestion === false ? (
                <>
                  <ErrorOutline></ErrorOutline>
                  <Typography>{translate('codeEditor.turnIntoQuestion')}</Typography>
                </>
              ) : (
                <>
                  <Close />
                  <Typography>{translate('codeEditor.removeQuestion')}</Typography>
                </>
              )}
            </Box>
          )}
          {domain.ui.isContextOpen.value && (
            <CodeContextMenu
              currentFile={domain.ui.currentFile.value}
              newFolder={domain.newFolder}
              newFile={domain.newFile}
              open={domain.ui.isContextOpen.value}
              onClose={domain.closeContextMenu}
              onDelete={domain.onDelete}
              onRename={domain.onRename}
              toggleQuestion={domain.toggleQuestion}
              // @ts-ignore
              event={domain.ui.event?.value}
            />
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          {domain.ui.mode.value === CodeMode.review && lang !== null ? (
            <ReviewEditor
              lang={lang}
              file={domain.ui.currentFile.value}
              pickingOption={domain.pickingOption}
              currentOption={domain.ui.currentOption}
              addOption={(val) => domain.addOption(val)}
              deleteOption={domain.deleteOption}
              toggleOption={domain.toggleOption}
              height={editorHeight}
              headerHeight={Number(headerRef?.clientHeight)}
              isExpand={isExpand}
            />
          ) : (
            <Typography sx={{ padding: '4px 8px' }}>Untitled</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
});
