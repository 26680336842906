import { ConfigNotLoadFromServerError } from "./error/ConfigNotLoadFromServerError";
import { IVKConfig } from "./IVKConfig";

export class VKConfigManager {
  static serverConfig: IVKConfig = {
    redirectURL: '',
    clientId: ''
  }

  static async loadConfigFromBackend () {
    const baseUrl = window.location.protocol + '//' + window.location.host;
    const response = await fetch(baseUrl + '/v2/api/vk/sys/config');

    if (!response.ok) {
      throw new ConfigNotLoadFromServerError();
    }
    const config = await response.json();

    VKConfigManager.serverConfig = config;
  }

  static setConfig(config) {

  }
}
