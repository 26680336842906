import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { CoreRestCRUDService } from '../coreCommon/common/rest/CoreRestCRUDService';
import { IFileStoreModel } from './IFileStoreModel';
import { FileType, IFileStoreService, IuploadImageResponse } from './IFileStoreService';

const URL_IMG = '/image';
const URL_DOC = '/doc';
const URL_VIDEO = '/video';

type IURLS = typeof URL_IMG | typeof URL_DOC | typeof URL_VIDEO;

const FILE_URL_BY_TYPE: Record<FileType, IURLS> = {
  [FileType.IMAGE]: URL_IMG,
  [FileType.DOC]: URL_DOC,
  [FileType.VIDEO]: URL_VIDEO,
};

export class RestFileStoreService extends CoreRestCRUDService<IFileStoreModel> implements IFileStoreService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/minio_client`;
  }

  async uploadImage(data: any, fileType: FileType): Promise<IuploadImageResponse> {
    const formData = new FormData();
    formData.append(fileType, data);

    return fetch(this.getEndpoint() + FILE_URL_BY_TYPE[fileType], {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (res.ok) return res.json();

      throw res;
    })
    .catch(this.handleError);
  }

  async uploadImageWithData(data: any, fileType: FileType): Promise<any> {
    const formData = new FormData();
    formData.append(fileType, data);

    let response;

    switch (fileType) {
      case FileType.IMAGE:
        response = await fetch(this.getEndpoint() + '/image-withdata', {
          method: 'POST',
          body: formData,
        }).then((res) => res.json());

        const sizeInMB = (data.size / (1024 * 1024)).toFixed(2) + ' MB';
        return {
          fileUrl: response.fileUrl,
          name: data.name,
          size: sizeInMB,
        };
      }
  }

  async deleteByFile(fileName: string, fileType: FileType): Promise<any> {
    const response = await fetch(this.getEndpoint() + '/' + fileType + '/' + fileName, {
      method: 'DELETE',
    }).then((res) => res.json());

    return response;
  }
}
