import { DeleteOutline } from '@mui/icons-material';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { FormMultiAutocompleteStandard } from '../../../../../design/form/FormMultiAutocompleteStandard';
import { FormTextFieldStandard } from '../../../../../design/form/FormTextFieldStandard';
import { UnitDomain } from '../../store/UnitDomain';
import { useFormValidation as useSettingsForm } from './hooks/useSettingsForm';
import { SchemaStockSVGru } from './SchemaStockSVGru';
import { SchemaStockSVGen } from './SchemaStockSVGen';
import { UploadPreviewDomain } from './uploadPreview/store/UploadPreviewDomain';
import { UploadPreview } from './uploadPreview/UploadPreview';
import { mapToAutocompleteItems } from './utils/mapToAutocompleteItems';
import { useI18n } from '../../../../../../../../application/language/useI18n';
import { TranslateText } from '../../../../../../../../application/language/TranslateText';

interface ISettingsProperties {
  unitDomain: UnitDomain;
}

export const Settings = observer(({ unitDomain }: ISettingsProperties) => {
  const {translate, getCurrentLanguage} = useI18n();
  const { validationErrors, onTitleChange } = useSettingsForm(unitDomain);
  const [uploadDomain] = useState<UploadPreviewDomain>(new UploadPreviewDomain(unitDomain.layoutDomain));
  const styles = useStyles();

  const handleFileChange = (file) => {
    uploadDomain.ui.files.setList([file]);
    unitDomain.ui.unit.entity.settings.preview = {
      ...unitDomain.ui.unit.entity.settings.preview,
      data: file.data,
      name: file.name,
      size: file.size,
    };
  };

  const handleDelete = () => {
    uploadDomain.ui.files.setList([]);
    unitDomain.ui.unit.entity.settings.preview = {
      ...unitDomain.ui.unit.entity.settings.preview,
      data: '',
      name: '',
      size: '',
    };
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingTop: '16px',
        height: 'calc(100vh - 320px)',
        overflowY: 'scroll',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6"><TranslateText i18nKey={'phrases.system'} /></Typography>
        <FormTextFieldStandard
          variant="outlined"
          label={`${translate('phrases.nameOfUnit')}*`}
          errorMessage={validationErrors?.title}
          value={unitDomain.ui.unit.entity.settings.titleHeading}
          onChange={onTitleChange}
        />
        <FormMultiAutocompleteStandard
          variant="outlined"
          values={mapToAutocompleteItems({
            items: unitDomain.ui.characteristic.list,
            valueKey: 'id',
            nameKey: 'name',
          })}
          label={translate('phrases.specifications')}
          selected={unitDomain.ui.unit.entity.settings.characteristicsIds}
          onChangeSelect={(characteristics) => {
            unitDomain.ui.unit.entity.settings.characteristicsIds = characteristics?.map((characteristic) => {
              return characteristic.value;
            });
          }}
        />
        <FormMultiAutocompleteStandard
          variant="outlined"
          values={mapToAutocompleteItems({ items: unitDomain.ui.teamRoles.list, valueKey: 'id', nameKey: 'name' })}
          label={translate('phrases.recommendedForRoles')}
          selected={unitDomain.ui.unit.entity.settings.teamRolesIds}
          onChangeSelect={(roles) => {
            unitDomain.ui.unit.entity.settings.teamRolesIds = roles.map((item) => item.value);
          }}
        />
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: '1 1 70%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="h6"><TranslateText i18nKey={'phrases.unitCover'} /></Typography>
            <UploadPreview uploadDomain={uploadDomain} onChange={handleFileChange} />
          </Box>
          <Box sx={{ flex: '1 1 30%', display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="h6"><TranslateText i18nKey={'phrases.imageDiagram'} /></Typography>
            {unitDomain.ui.unit.entity.settings.preview.data ? (
              <Box className={styles.previewCardWrapper}>
                <img
                  src={unitDomain.ui.unit.entity.settings.preview.data}
                  className={styles.previewImage}
                  alt={unitDomain.ui.unit.entity.settings.preview.name}
                />
                <Box className={styles.previewCardFooter}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: 'auto' }}>
                    <Typography
                      className={styles.previewFileName}
                      variant="body2"
                      color={'rgba(255, 255, 255, 0.7)'}
                      title={unitDomain.ui.unit.entity.settings.preview.name}
                    >
                      {unitDomain.ui.unit.entity.settings.preview.name}
                    </Typography>
                    <Typography variant="body2">{unitDomain.ui.unit.entity.settings.preview.size}</Typography>
                  </Box>
                  <IconButton onClick={handleDelete} sx={{ color: 'rgba(244, 67, 54, 1)' }}>
                    <DeleteOutline />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              getCurrentLanguage() === 'ru' ? <SchemaStockSVGru />: <SchemaStockSVGen />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  previewCardWrapper: {
    maxHeight: '284px',
    maxWidth: '312px',
    height: '100%',
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  previewImage: {
    height: '228px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '8px 8px 0 0',
  },
  previewFileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    cursor: 'default',
  },
  previewCardFooter: {
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    display: 'flex',
    justifyContent: 'end',
    padding: '8px 16px',
    height: '56px',
    backgroundColor: '#29323b',
    borderRadius: '0 0 8px 8px',
  },
}));
