import { CssBaseline, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { VKOAUTHRedirectPageDomain } from './store/VKOAUTHRedirectPageDomain';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),
    flexDirection: 'column'
  }
}));


export const VKOAUTHRedirectPage = observer(() => {
  const [domain] = useState(new VKOAUTHRedirectPageDomain());

  useEffect(() => {
    domain.boot();
  })
  const classes = useStyles();

  return <div className={classes.page}>
    <CssBaseline />
    <Typography variant='h5' >Ожидание авторизации...</Typography>
    <LinearProgress sx={{ width: '288px' }} />
  </div>
})