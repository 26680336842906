import React from 'react';
import { Autocomplete, Checkbox, TextField, Theme } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { makeStyles } from '@mui/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IManagerFilterDomain } from './ManagerFilterDomain';
import { observer } from 'mobx-react';
import { useI18n } from '../../../application/language/useI18n';

export interface IManagerFilterAutocompliteProps {
  domain: IManagerFilterDomain;
}

export const ManagerFilterAutocomplite = observer(({domain}: IManagerFilterAutocompliteProps) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();
  const managers = domain?.managersList?.list || []
  const { translate } = useI18n()

  return (
    <>
      <Autocomplete
        multiple
        className={classes.managerFilter}
        options={managers}
        style={{ fontSize: 6 }}
        disableCloseOnSelect
        size="small"
        loading={domain?.isManagersLoading?.value || false}
        loadingText={translate('managerFilter.searching')}
        noOptionsText={translate('managerFilter.noResults')}
        onChange={(event, value) => {
          domain.setFilteredManagersList(value || [])
        }}
        getOptionLabel={(option) => option.displayName}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.displayName}
          </li>
        )}
        renderInput={(params) =>
          <TextField
            {...params}
            multiline={false}
            label={translate('managerFilter.manager')} />}
      ></Autocomplete>
    </>
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  searchBlock: {
    width: '100%',
    marginRight: 'auto',
    margin: 0,
    fontSize: '13px',
    marginTop: 15,
    display: 'flex',
  },
  managerFilter: {
    width: '20%',
    minWidth: '200px',
    fontSize: '13px',
  },
  searchInput: {
    marginRight: 10,
    width: "100%"
  },
}));

