import { IRootPublicService } from '../../../../../service/public/RootPublicService';
import { IRequirementModel } from '../../../../../service/requirement/entity/IRequirementModel';
import { IRootAdminService } from '../../../../../service/RootAdminService';
import { RequirementViewModel } from '../../../../user/page/application/info/store/RequirementViewModel';
import { CommonFilterUI } from './CommonFilterUI';

interface IAppReqData {
  applicationReqId: string | undefined;
  requirement: IRequirementModel;
}
export class CommonFilterDomain {
  private defaultSearchCondition = {
    limit: 100000,
    filter: { isActive: true },
  };

  protected extensionFunctionality: { filters: Map<string, Function>; clearSearch: Map<string, Function> } = {
    filters: new Map(),
    clearSearch: new Map(),
  };
  protected;

  constructor(protected rootService: IRootPublicService | IRootAdminService, public ui = new CommonFilterUI()) {}

  clearSearch() {
    this.ui.clearSearch();
    this.triggerSearch();
    for (const [id, clearSearch] of this.extensionFunctionality.clearSearch) {
      clearSearch();
    }
  }

  addExtensionFunctionality(id: string, value: Function, functionality: 'filters' | 'clearSearch') {
    this.extensionFunctionality[functionality].set(id, value);
  }

  async triggerSearch() {
    let requirements = this.getRequirements();

    for (const [id, filter] of this.extensionFunctionality.filters) {
      requirements = filter(requirements, this);
    }

    this.setRequirements(requirements);
  }

  getRequirements(): IRequirementModel[] {
    return [];
  }

   setRequirements(requirements: IAppReqData[] |  IRequirementModel[]) {}

  async start() {
    await this.setSettings();
    await this.loadData();
  }

  protected async loadData() {
    this.ui.isLoading.setValue(true);
    await Promise.all([this.loadSpecifications(), this.loadStatuses(), this.loadTags(), this.loadVendors()]);
    this.ui.isLoading.setValue(false);
  }

  protected async loadSpecifications() {
    const [specifications, specificationsCategories] = await Promise.all([
      this.rootService.specification.entity.search(this.defaultSearchCondition),
      this.rootService.specification.category.search(this.defaultSearchCondition),
    ]);

    this.ui.specifications.setList(specifications.data);
    this.ui.specificationsCategories.setList(specificationsCategories.data);
  }

  protected async loadTags() {
    const [tags, tagsCategories] = await Promise.all([
      this.rootService.tag.entity.search(this.defaultSearchCondition),
      this.rootService.tag.category.search(this.defaultSearchCondition),
    ]);

    this.ui.tags.setList(tags.data);
    this.ui.tagsCategories.setList(tagsCategories.data);
  }

  protected async loadStatuses() {
    const [statuses, statusesValues] = await Promise.all([
      this.rootService.status.entity.search(this.defaultSearchCondition),
      this.rootService.status.value.search(this.defaultSearchCondition),
    ]);

    this.ui.statuses.setList(statuses.data);
    this.ui.statusesValues.setList(statusesValues.data);
  }

  protected async loadVendors() {
    const vendors = await this.rootService.vendor.entity.search(this.defaultSearchCondition);
    this.ui.vendors.setList(vendors.data);
  }

  protected async setSettings() {
    this.ui.isShow.setValue(true);
    this.ui.isShowTextSearch.setValue(true);
    this.ui.isShowSpecificationsSearch.setValue(true);
    this.ui.isShowStatusesSearch.setValue(true);
    this.ui.isShowTagsSearch.setValue(true);
    this.ui.isShowVendorsSearch.setValue(true);
  }
}
