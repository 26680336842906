import { Box } from '@mui/material';
import React from 'react';

import { AppSecIcon } from './AppSecIcon';
import { HFIcon } from './HFIcon';

export const RSSecurityBottomLogo = () => {
  return (
    <Box sx={{ display: 'flex', gap: '14px', padding: '27px 16px' }}>
      <HFIcon />
      <AppSecIcon />
    </Box>
  );
};
