import React, { memo } from 'react';
import { ITranslationsKeys } from '../../locales/ITranslationsKeys';
import { ITranslateOptions } from './ILocalization';
import { useI18n } from "./useI18n";

type IProps = {
  i18nKey: ITranslationsKeys;
  options?: ITranslateOptions
}

export const TranslateText = memo(({ i18nKey, options }: IProps): JSX.Element => {
  const { translate } = useI18n();

  return <>{translate(i18nKey, options)}</>
})