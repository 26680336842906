import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useI18n } from '../../application/language/useI18n';
import { inject } from '../../common/container/inject';
import { IMainLayoutDomainStore } from '../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationTheme } from './application/theme/ApplicationTheme';
import { IRouterService, RouterServiceToken } from './service/route/IRouterService';
import { UserInfoCard } from './view/admin/page/passingListPage/parts/UserInfoCard';
import { PassingListPage } from './view/admin/page/passingListPage/PassingListPage';
import { IPassingListPageDomain } from './view/admin/page/passingListPage/store/IPassingListPageDomain';
import { PassingListPageDomain } from './view/admin/page/passingListPage/store/PassingListPageDomain';
import { UserRating } from './view/admin/page/usersRating/UserRating';
import { Colors } from './view/design/colors/Colors';
import { useDeferredCallback } from './view/shared/hooks/useDeferredCallback';

export const LearningUserPage = observer(({ layoutDomain }: { layoutDomain: IMainLayoutDomainStore }) => {
  const [domain] = useState<IPassingListPageDomain>(new PassingListPageDomain(layoutDomain));
  const routes = [`/learning/user`, `/learning/user/analytics`];
  const router = inject<IRouterService>(RouterServiceToken);
  const [tabValue, setTabValue] = useState(router.getActiveUrl());
  const history = useHistory();
  const i18n = useI18n();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    history.push(newValue);
  };
  const classes = useStyles();

  useDeferredCallback(() => {
    const currentUser = layoutDomain.ui.activeUser.entity;
    domain.loadData(currentUser.id);
  }, [domain, layoutDomain.ui.activeUser.entity]);

  return (
    <ApplicationTheme>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ paddingLeft: '16px' }}>
          {i18n.translate('learningUserPage.learningTitle')}
        </Typography>

        <UserInfoCard
          fio={layoutDomain.ui.activeUser.entity.displayName}
          role={layoutDomain.ui.activeUser.entity.systemRoleName}
          userData={domain.ui.userData.value}
        />
      </Box>

      <Box sx={{ width: '100%', typography: 'body1', position: 'relative' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <TabList onChange={handleChangeTab} classes={{ indicator: classes.indicator }}>
              <Tab
                sx={{ textTransform: 'capitalize' }}
                label={i18n.translate('learningUserPage.skillsMap')}
                value={routes[0]}
              />
              <Tab
                sx={{ textTransform: 'capitalize' }}
                label={i18n.translate('learningUserPage.usersRating')}
                value={routes[1]}
              />
            </TabList>
          </Box>
          <TabPanel value={routes[0]} className={classes.tabPanelPassing}>
            <PassingListPage layoutDomain={layoutDomain} passingListPageDomain={domain} />
          </TabPanel>
          <TabPanel value={routes[1]} className={classes.tabPanelRating}>
            <UserRating layoutDomain={layoutDomain} />
          </TabPanel>
        </TabContext>
      </Box>
    </ApplicationTheme>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
  tabPanelPassing: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  tabPanelRating: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: 'calc(100vh - 220px)',
    },
  },
}));
