import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { useI18n } from '../../../../../../../../application/language/useI18n';
import { ITranslationsKeys } from '../../../../../../../../locales/ITranslationsKeys';
import { SingleResultForm } from './components/SingleResultForm';
import { IUnitFinalResultsDomain } from './store/IUnitFinalResultsDomain';

export interface IFinalResultsProperties {
  domain: IUnitFinalResultsDomain;
}

const getResultsTitleMap = (translate: (key: ITranslationsKeys, options?: Record<string, any>) => string) => [
  { title: translate('finalResults.successTitle'), subtitle: translate('finalResults.successSubtitle') },
  { title: translate('finalResults.neutralTitle'), subtitle: translate('finalResults.neutralSubtitle') },
  { title: translate('finalResults.failedTitle'), subtitle: translate('finalResults.failedSubtitle') },
];

export const FinalResults = observer(({ domain }: IFinalResultsProperties) => {
  const { translate } = useI18n();
  const { success, neutral, failure } = domain.unitDomain.ui.unit.entity.finalResult.results;

  useEffect(() => {
    domain.boot();
  }, [domain]);

  const classes = useStyles();

  const formValues = [success, neutral, failure].map((item, index) => ({
    item: item,
    titleValue: item.title,
    subtitleValue: item.subtitle,
  }));

  console.log('getResultsTitleMap: ', getResultsTitleMap(translate)[0], getResultsTitleMap(translate)[1], getResultsTitleMap(translate)[2])

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h6">{translate(`phrases.results`)}</Typography>
      <div className={classes.formContainer}>
        {formValues.map((item, index) => (
          <SingleResultForm item={item.item} title={getResultsTitleMap(translate)[index].title} subtitle={getResultsTitleMap(translate)[index].subtitle} />
        ))}
      </div>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));
