import { Alert, AlertTitle } from '@mui/lab';
import { Box, CircularProgress, Grid, Paper, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ICommonProperties } from '../../../../../../common/properties/ICommonProperties';
import { TaskManagerConnectionState } from '../../../../../../service/taskManager/ITaskManager';
import { FormTextField } from '../../../../../design/form/FormTextField';
import { ApplicationDetailDomain } from '../store/ApplicationDetailDomain';
import { ApplicationDetailsMode } from '../store/ApplicationDetailsMode';
import { ApplicationHeaderTabs } from './ApplicationHeaderTabs';

export interface IApplicationInfoProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export const ApplicationInfoContent = observer(({ domain }: IApplicationInfoProperties) => {
  const classes = useStyles();
  const isEdit =
    domain.ui.mode.value === ApplicationDetailsMode.edit &&
    (domain.ui.isCanUpdate.value || domain.ui.isCanCreate.value);
  const newMessagesCount = Number(domain.ui.applicationNewMessagesState.value?.applicationNewMessages || 0);
  const isHaveNewMessages = newMessagesCount > 0;

  const createProjectLink = (projectId: string): string => {
    return `/project/${projectId}/info`;
  };

  useEffect(() => {
    domain.ui.changeTab(isEdit ? 'form' : domain.ui.tabsData.value.defaultViewKey);
  }, [isEdit]);
  return (
    <Fragment>
      {domain.ui.editJiraTaskManager.entity.connectionState === TaskManagerConnectionState.errors && (
        <Alert severity="error">
          <AlertTitle>Ошибка синхронизации c Jira</AlertTitle>
          Невозможно синхронизироваться с Jira. Нужно проверить настройки анкеты или проверить настройки прав
          пользователя в Jira!
        </Alert>
      )}
      <Paper className={classes.root} elevation={domain.projectFormDomain ? 0 : 3}>
        {!domain.projectFormDomain && !domain.ui.application.entity.isUnitedWithProject ? (
          domain.ui.project.entity.id && domain.ui.project.entity.name ? (
            <Link className={classes.link} to={createProjectLink(domain.ui.project.entity.id)}>
              {domain.ui.project.entity.name}
            </Link>
          ) : (
            <Typography variant="h6">{domain.ui.project.entity.name}</Typography>
          )
        ) : null}
        {isEdit && !domain.projectFormDomain && (
          <FormTextField
            errorMessage={domain.getApplicationValidationErrorFor('name')?.message}
            value={domain.ui.editApplication.entity.name}
            onChange={domain.getUpdateFieldHandler('name')}
            label="Название"
            helperText={'Данное поле должно содержать полное название Системы'}
            required={true}
          />
        )}
        {!isEdit && !isHaveNewMessages && (
          <Typography variant="h4">
            {domain.ui.application.entity.name}
            {domain.ui.getAcceptStatusName()}
          </Typography>
        )}
        {!isEdit && isHaveNewMessages && (
          <Grid container>
            <Grid item>
              <Typography variant="h4">
                {domain.ui.application.entity.name}
                {domain.ui.getAcceptStatusName()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">({newMessagesCount})</Typography>
            </Grid>
          </Grid>
        )}
        <Toolbar />
        <ApplicationHeaderTabs domain={domain} />
      </Paper>

      {domain.ui.isCompletePageStartLoading.value ? domain.ui.tabsData.value.tabs.map((item, index) => {
        const PageComponent = item.Component;

        return domain.ui.tabsData.value.state === index ? (
          <Fragment>
            <div>
              <PageComponent domain={domain} />
            </div>
          </Fragment>
        ) : (
          <Fragment />
        );
      }) : <Box sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '24px 15px'
      }}>
        <CircularProgress />
      </Box>}
    </Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));
