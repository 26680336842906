import 'date-fns';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material';
import ruLocale from 'date-fns/locale/ru';
import React, { Fragment, useEffect, useState } from 'react';

import { loadExtension } from './../extension/loadExtension';
import { ContainerContext, coreContainer, extensionContainer } from '../common/container/ContainerContext';
import ErrorBoundaryApp from '../common/errorPage/ErrorBoundary/ErrorBoundaryApp';
import ApplicationRouter from './ApplicationRouter';
import { ConfigManager } from './config/ConfigManager';
import { RootContainerType, rootContainerFactory } from './containers/rootContainerFactory';
import ApplicationTheme from './theme/ApplicationTheme';

const Application = () => {
  //@ts-ignore
  // window.isExtensionLoaded = false;
  const [isExtensionsLoaded, setExtensionsLoaded] = useState<boolean>(false);

  const [extensionContainerState] = useState(extensionContainer);
  const [coreContainerState] = useState(coreContainer);
  useEffect(() => {
    const load = async () => {
      //@ts-ignore
      if (isExtensionsLoaded === false && coreContainerState && extensionContainerState && loadExtension) {
        await rootContainerFactory(RootContainerType.default, coreContainerState);
        const extensions = ConfigManager.getConfig()?.extensions?.include;
        // @todo remove hotfix
        if (extensions?.includes('mvideo')) {
          extensions.unshift('riskManager');
        }

        //@ts-ignore
        await loadExtension(extensions || [], extensionContainerState);
        //@ts-ignore

        setExtensionsLoaded(true);
      }
    };

    load();
    //@ts-ignore
  }, [isExtensionsLoaded, coreContainerState, extensionContainerState]);

  return (
    <Fragment>
      <ContainerContext.Provider value={coreContainerState}>
        <StyledEngineProvider injectFirst>
          <ApplicationTheme>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
              <ErrorBoundaryApp>{isExtensionsLoaded && <ApplicationRouter />}</ErrorBoundaryApp>
            </LocalizationProvider>
          </ApplicationTheme>
        </StyledEngineProvider>
      </ContainerContext.Provider>
    </Fragment>
  );
};

export default Application;
