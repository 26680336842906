import { useEffect } from "react";

export const useDeferredCallback = (clb: () => void, deps: unknown[]) => {
  useEffect(() => {
    let isUnmount = false;
    const loading = () => {
      if (isUnmount) return;
      clb();
    }

    setTimeout(() => {
      loading();
    }, 0);

    return () => {
      isUnmount = true;
    }
  }, deps);
}