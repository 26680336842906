import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ILocalization, ITranslateOptions } from './ILocalization';

import enTranslation from '../../locales/en/translation';
import { ITranslationsKeys } from '../../locales/ITranslationsKeys';
import ruTranslation from '../../locales/ru/translation';

const resources = {
  en: {
    translation: enTranslation
  },
  ru: {
    translation: ruTranslation
  }
};


export class Translation implements ILocalization {
  constructor(public i18n: typeof i18next) { }

  translateAnyway = (key: string, options?: ITranslateOptions): string => {
    return this.i18n.t(key, options);
  }

  translate = (key: ITranslationsKeys, options?: ITranslateOptions): string => {
    return this.translateAnyway(key, options);
  };

  changeLanguage = (language: string) => {
    this.i18n.changeLanguage(language);
  };

  getCurrentLanguage = () => this.i18n.language
}

export const createTranslation = async (): Promise<ILocalization> => {
  if (!i18next.isInitialized) {
    await i18next
      .use(LanguageDetector)
      .init({
        resources,
        fallbackLng: ['en', 'ru'],
        debug: false,
        detection: {
          order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
          lookupQuerystring: 'lng',
          lookupLocalStorage: 'i18nextLng',
        },
        interpolation: {
          escapeValue: false,
        },
        preload: ['en', 'ru'],
      });
  }

  return new Translation(i18next);
};
