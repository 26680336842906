import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';

import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { SpecificationCategoryType } from '../../../../../../service/specification/category/ISpecificationCategoryModelConditionMap';
import { ISpecificationCategorySearch } from '../../../../../../service/specification/category/ISpecificationCategorySearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';

export class SpecificationCategoryAdminUI
  extends DataTableUI<ISpecificationCategoryModel, ISpecificationCategorySearch>
  implements IDataTableUI<ISpecificationCategoryModel, ISpecificationCategorySearch> {
  constructor(
    public domain: IDataTableDomain<ISpecificationCategoryModel, ISpecificationCategorySearch>,
    public layoutDomain: IMainLayoutDomainStore,
  ) {
    super(domain, null);
  }
  setDomainId(): string {
    return 'settings/specification/category';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: this.i18n.translate('systemSpecificationsPage.name'),
        field: 'name',
        flex: 1,
        renderCell: (params: { value?: string, row?: ISpecificationCategoryModel }) => {
          const categoryName = params.row?.categoryType === SpecificationCategoryType.specificationCategoryText ? 'Текстовое поле' : 'Выбор значения'
          return (
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '12px',
                letterSpacing: '0.15px',
                wordWrap: 'break-word',
                marginTop: '-12px'
              }}>
                {categoryName}
              </div>
              <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />
            </Grid >
          )
        },
      },
      {
        headerName: this.i18n.translate('systemSpecificationsPage.description'),
        field: 'description',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: this.i18n.translate('systemSpecificationsPage.ordering'),
        type: 'numeric',
        field: 'orderIndex',
        flex: 0.7,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: this.i18n.translate('systemSpecificationsPage.active'),
        field: 'isActive',
        type: 'boolean',
        flex: 0.5,
        headerAlign: 'left',
        align: 'left'
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: this.i18n.translate('phrases.actions'),
      flex: 0.5,
      minWidth: 60,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title={this.i18n.translate('phrases.edit')}>
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                style={{ marginRight: '8px' }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={this.i18n.translate('phrases.delete')}>
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
