import { ArrowDownward, ArrowUpward, DeleteForever, ExpandMore, VisibilityOff } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React, { ChangeEventHandler, useCallback, useState } from 'react';

import { TranslateText } from '../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../application/language/useI18n';
import { IMainLayoutDomainStore } from '../../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { BlockType, ITheoryBlock, ITheoryBlocks } from '../../../../../../service/unit/interface/IUnitTheory';
import { ITabs } from '../../../../../../service/unit/interface/theoryBlocks/ITabs';
import { generateUUID } from '../../../../../../utils/generateUUID';
import { FormTextFieldStandard } from '../../../../form/FormTextFieldStandard';
import { ElementFactory } from '../ElementFactory';
import { NestingButton } from './NestingButton';
import { useAdditionalElementsList } from './useAdditionalElementsList';

export interface IAdditionalElementsList {
  title: string;
  element: ITheoryBlock<any>;
}

export const ADDITIONAL_ELEMENTS_TYPE_LIST_FOR_SPECIAL_TABS = [
  BlockType.PARAGRAPH,
  BlockType.LIST,
  BlockType.TABLE,
  BlockType.CODE_EXAMPLE,
  BlockType.SPECIAL_BLOCK,
  BlockType.IMAGE,
  BlockType.VIDEO,
  BlockType.TAGS,
];

export interface ItabsBlock {
  layoutDomain: IMainLayoutDomainStore,
  data: ITheoryBlock<ITabs>;
  onDelete: Function;
  onUp: Function;
  onDown: Function;
}
export interface IPickedtab {
  name: string;
  subBlocks: ITheoryBlock<ITheoryBlocks>[];
}
export const TabsBlock = observer(({ data: { data, pseudoId }, onDelete, onUp, onDown, layoutDomain }: ItabsBlock) => {
  const { translate } = useI18n()
  const [pickedtab, setPickedtab] = useState<IPickedtab>(null as any);
  const { additionalElementsList } = useAdditionalElementsList({ includes: ADDITIONAL_ELEMENTS_TYPE_LIST_FOR_SPECIAL_TABS });

  const addNewElement = (block) => {
    const newBlock: ITheoryBlock<any> & { Element?: any } = JSON.parse(JSON.stringify(block));
    newBlock.pseudoId = generateUUID();
    newBlock.Element = ElementFactory[newBlock.block];
    data.tabsData.contentBlocks.forEach((tab) => {
      if (tab.name === pickedtab.name) {
        tab.subBlocks.push(newBlock);
      }
    });
  };

  const onDeleteSubBlock = (block, name: string) => {
    data.tabsData.contentBlocks.forEach((tab) => {
      if (tab.name === name) {
        const index = tab.subBlocks.indexOf(block);
        if (index !== -1) {
          tab.subBlocks.splice(index, 1);
        }
      }
    });
  };
  const onUpSubBlock = (index: number, name: string) => {
    data.tabsData.contentBlocks.forEach((tab) => {
      if (tab.name === name) {
        if (index === 0 && tab.subBlocks.length !== 0) {
          const firstEl = tab.subBlocks.shift();
          // @ts-ignore
          tab.subBlocks.push(firstEl);
        } else {
          [tab.subBlocks[index], tab.subBlocks[index - 1]] = [tab.subBlocks[index - 1], tab.subBlocks[index]];
        }
      }
    });
  };
  const onDownSubBlock = (index: number, name: string) => {
    data.tabsData.contentBlocks.forEach((tab) => {
      if (tab.name === name) {
        if (index === tab.subBlocks.length - 1 && tab.subBlocks.length !== 0) {
          const lastEl = tab.subBlocks.pop();
          // @ts-ignore
          tab.subBlocks.unshift(lastEl);
        } else {
          [tab.subBlocks[index], tab.subBlocks[index + 1]] = [tab.subBlocks[index + 1], tab.subBlocks[index]];
        }
      }
    });
  };

  const onChangeCount: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    if (+event.target.value > data.tabsCount) {
      data.tabsCount = +event.target.value;
      data.tabsData.contentBlocks.push({ name: translate('createUnit.tabCount', { count: data.tabsCount }), subBlocks: [] });
    } else {
      data.tabsCount = +event.target.value;
      data.tabsData.contentBlocks.pop();
    }
  }, [data, translate]);

  return (
    <Box
      key={pseudoId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        background: 'rgba(0,0,0,0.2)',
        padding: '16px 42px ',
        '&:hover .action': { visibility: 'visible' },
      }}
    >
      <Accordion sx={{ background: 'transparent', boxShadow: 'none' }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            display: 'flex',
            '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
            alignItems: 'flex-start',
            '& .MuiAccordionSummary-content': {
              margin: 0,
              width: '100%',
              flex: 1,
            },
            '.Mui-expanded': {
              margin: '0 !important',
            },
            '&.Mui-expanded': {
              minHeight: '32px',
            },
            padding: 0,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '100%' }}>
            <Typography sx={{ marginRight: 'auto' }}>
              <TranslateText i18nKey='createUnit.tabs' />
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px', visibility: 'hidden' }} className="action">
              <DeleteForever
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDelete();
                }}
              />
              <VisibilityOff fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => { }} />
              <ArrowUpward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onUp();
                }}
              />
              <ArrowDownward
                fontSize="small"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  onDown();
                }}
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            // @ts-ignore
            onWheel={(e) => e.target.blur()}
            variant="outlined"
            type="number"
            value={data.tabsCount}
            label={translate('createUnit.quantity')}
            InputProps={{ inputProps: { min: 0 } }}
            onChange={onChangeCount}
          />
          <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            {data.tabsData.contentBlocks.map((field, index) => {
              return (
                <FormTextFieldStandard
                  inputProps={{ maxLength: 15 }}
                  variant="outlined"
                  label={translate('createUnit.title')}
                  helperText={translate('validation.notMoreThanSymbols', { number: 15 })}
                  value={field.name}
                  onChange={(newValue) => {
                    data.tabsData.contentBlocks[index].name = newValue;
                  }}
                />
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            {data.tabsData.contentBlocks.map((tab) => {
              return (
                <Box
                  onClick={() => setPickedtab({ name: tab.name, subBlocks: tab.subBlocks })}
                  sx={{
                    flex: 1,
                    maxWidth: 'calc(100% / 7)',
                    width: '100%',
                    '&:hover': {
                      color: '#1976D2',
                      borderBottom: '1px solid #1976D2',
                    },
                    padding: '8px 16px',
                    color: pickedtab !== null && tab.name === pickedtab.name ? '#1976D2' : 'white',
                    borderBottom:
                      pickedtab !== null && tab.name === pickedtab.name ? '1px solid #1976D2' : '1px solid white',
                    cursor: 'pointer',
                  }}
                >
                  {tab.name}
                </Box>
              );
            })}
          </Box>
          {pickedtab !== null && (
            <Box className="tab_content" sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {pickedtab.subBlocks.map((block, index) => {
                  const Element = ElementFactory[block.block];
                  const elementData = { data: block.data, pseudoId: block.pseudoId };
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                        '& > div': {
                          background: 'transparent',
                        },
                      }}
                      key={block.pseudoId}
                    >
                      <Element
                        layoutDomain={layoutDomain}
                        data={elementData}
                        onDelete={() => onDeleteSubBlock(block, pickedtab.name)}
                        onUp={() => onUpSubBlock(index, pickedtab.name)}
                        onDown={() => onDownSubBlock(index, pickedtab.name)}
                      />
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
                {additionalElementsList.map((button) => {
                  return <NestingButton title={button.title} action={() => addNewElement(button.element)} />;
                })}
              </Box>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});
