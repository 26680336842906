import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { sortCallBack } from './sortOrder';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';

function mergeArrays(
  categories: any[] = [],
  domain
) {
  const mappedCategories = categories.map((category) => {
    const specifications = domain.ui
      .specificationsByCategoryId(category.id)
      .map((specification) => specification.name)
      .join('\n');
    return { name: category.name, value: specifications };
  });

  return [...mappedCategories];
}

const sortedAllFields = (data) => data.sort(sortCallBack);

export const ViewModeListsExtended = observer(({ domain }: { domain: ApplicationDetailDomain }) => {
  const sortedData = sortedAllFields(
    mergeArrays(
      domain.ui.applicationSpecificationsCategories.list,
      domain,
    ),
  );

  return (
    <>
      {sortedData.map((item) => (
        <Grid item key={item.name}>
          <Typography variant="h5">{item.name}: </Typography>
          <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
            {item.value}
          </Typography>
        </Grid>
      ))}
    </>
  );
});
