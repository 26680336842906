import { Button, Link } from '@mui/material';
import React, { ReactNode } from 'react';
import { IdGenerator } from '../../../../../../common/generator/IdGenerator';
import { VKConfigManager } from "../../../../service/config/VKConfigManager";

export const VKAOUTHButton = (): ReactNode => {
  const vkOUATHHref = React.useMemo(() => {
    const VK_OAUTH_URL = new URL('https://auth.vk.team/oauth2/authorize/');
    VK_OAUTH_URL.searchParams.append('client_id', VKConfigManager.serverConfig.clientId)
    VK_OAUTH_URL.searchParams.append('scope', 'user_info')
    VK_OAUTH_URL.searchParams.append('response_type', 'code')
    VK_OAUTH_URL.searchParams.append('state', IdGenerator.generate())


    return VK_OAUTH_URL.href;
  }, [])

  return (
    <div>
      <Link href={vkOUATHHref} underline="none">
        <Button variant="outlined" fullWidth>
          SYS АВТОРИЗАЦИЯ
        </Button>
      </Link>
    </div>
  )
}