import { Download } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Menu, MenuItem, Tab, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TranslateText } from '../../application/language/TranslateText';
import { ContainerContext } from '../../common/container/ContainerContext';
import { inject } from '../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../service/route/IRouterService';
import { MainLayoutBottomLogoMenu } from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';
import { ApplicationTheme } from './application/theme/ApplicationTheme';
import { templatePath } from './learningInjects';
import { IAdminPageProperties } from './view/admin/page/IAdminPageProperties';
import { UnitDomain } from './view/admin/page/unit/store/UnitDomain';
import { UnitListSearch } from './view/admin/page/unitListPage/parts/UnitListSearch';
import { IUnitListAdminPageDomain } from './view/admin/page/unitListPage/store/IUnitListAdminPageDomain';
import { UnitListAdminPageDomain } from './view/admin/page/unitListPage/store/UnitListAdminPageDomain';
import { UnitListAdminPage } from './view/admin/page/unitListPage/UnitListAdminPage';
import { UserRating } from './view/admin/page/usersRating/UserRating';
import { Colors } from './view/design/colors/Colors';
import { LearningReportDomain } from './view/user/projectList/domain/LearningReportDomain';

export const LearningAdminPage = observer(({ layoutDomain }: IAdminPageProperties) => {
  const router = inject<IRouterService>(RouterServiceToken);
  const [domain] = useState<IUnitListAdminPageDomain>(new UnitListAdminPageDomain(layoutDomain));
  const routes = ['/learning/admin', '/learning/admin/analytics'];
  const [unitDomain] = useState<UnitDomain>(new UnitDomain(layoutDomain));
  const [tabValue, setTabValue] = useState(router.getActiveUrl());
  const [reportDomain] = useState<LearningReportDomain>(new LearningReportDomain(layoutDomain));
  const history = useHistory();

  // @ts-ignore
  const container = useContext(ContainerContext);
  const unitSummuryTemplatePath = container.get(templatePath)();
  console.log('unitSummuryTemplatePath', unitSummuryTemplatePath);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    history.push(newValue);
  };

  const classes = useStyles();

  useEffect(() => {
    reportDomain.boot();
  }, [layoutDomain.ui.activeUser.entity.id]);

  return (
    <ApplicationTheme>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
          marginBottom: '24px',
          paddingBottom: '24px',
        }}
      >
        <Typography variant="h4" sx={{ paddingLeft: '16px' }}>
          <TranslateText i18nKey="learningUserPage.learningTitle" />
        </Typography>
        <UnitListSearch domain={domain} />
      </Box>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <TabList onChange={handleChangeTab} classes={{ indicator: classes.indicator }}>
              <Tab
                sx={{ textTransform: 'capitalize' }}
                label={<TranslateText i18nKey="learningUserPage.skillsMap" />}
                value={routes[0]}
              />
              <Tab
                sx={{ textTransform: 'capitalize' }}
                label={<TranslateText i18nKey="learningUserPage.usersRating" />}
                value={routes[1]}
              />
            </TabList>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Box>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  color="secondary"
                  variant="outlined"
                  startIcon={<Download />}
                  onClick={(event) => reportDomain.handleOpen(event)}
                >
                  <TranslateText i18nKey="learningAdminPage.download" />
                </Button>
                <Menu
                  id="context-menu"
                  anchorEl={reportDomain.anchorEl.value}
                  keepMounted
                  open={Boolean(reportDomain.anchorEl.value)}
                  onClose={reportDomain.handleClose}
                >
                  <MenuItem onClick={() => reportDomain.handleReportDocx(unitSummuryTemplatePath)}>
                    <TranslateText i18nKey="learningAdminPage.trainingReportDOCXLabel" />
                  </MenuItem>
                  <MenuItem onClick={() => reportDomain.handleReportPdf(unitSummuryTemplatePath)}>
                    <TranslateText i18nKey="learningAdminPage.trainingReportPDFLabel" />
                  </MenuItem>
                </Menu>
              </Box>
              <svg width="1" height="36">
                <rect width="1" height="36" fill="rgba(255, 255, 255, 0.12)" />
              </svg>
              <Box>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    unitDomain.createUnit().then((unitId) => {
                      router.goTo(`/learning/admin/create/${unitId}`);
                    });
                  }}
                >
                  <TranslateText i18nKey="learningAdminPage.newUnit" />
                </Button>
              </Box>
            </Box>
          </Box>
          <TabPanel value={routes[0]} className={classes.tabPanelPassingUnits}>
            <UnitListAdminPage domain={domain} />
          </TabPanel>
          <TabPanel value={routes[1]} className={classes.tabPanelPassingRating}>
            <UserRating layoutDomain={layoutDomain} />
          </TabPanel>
        </TabContext>
      </Box>
    </ApplicationTheme>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
  tabPanelPassingUnits: {
    padding: '0px',
    height: '100%',
  },
  tabPanelPassingRating: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: '100%',
    },
  },
  tabPanelRating: {
    padding: '0px',
    height: '100%',
    '& > div': {
      height: 'calc(100% - 150px)',
    },
  },
}));
