import { CloseOutlined, ErrorOutline, FileUploadOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import imageCompression from 'browser-image-compression';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { TranslateText } from '../../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../../application/language/useI18n';
import { FileType } from '../../../../../../../../../service/fileStore/IFileStoreService';
import { IErrorUpload } from '../../../../../../design/fileUpload/store/IFileUploadUI';
import { UploadPreviewDomain } from './store/UploadPreviewDomain';

export interface UploadPreviewProps {
  uploadDomain: UploadPreviewDomain;
  onChange: (file: { data: string; name: string; size: string }) => void;
}

const MIN_WIDTH = 600;
const MIN_HEIGHT = 500;

const useStyles = makeStyles((theme) => ({
  uploadWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '46px 0px',
    border: '2px dashed rgba(144, 202, 249, 0.16)',
    borderRadius: '8px',
    minHeight: '228px',
    maxHeight: '284px',
    height: '100%',
  },
  uploadContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  underlineLink: {
    color: 'rgba(144, 202, 249, 1)',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
    },
  },
  uploadButton: {
    width: 'fit-content',
    color: 'rgba(144, 202, 249, 1)',
    borderColor: 'rgba(144, 202, 249, 1)',
    padding: '8px',
    borderRadius: '8px',
    minWidth: 'auto',
    '&:hover': {
      color: 'white',
    },
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: 'rgba(244, 67, 54, 0.12)',
    borderRadius: '4px',
    height: '48px',
    padding: '0px 16px',
  },
}));

export const UploadPreview = observer(({ uploadDomain, onChange }: UploadPreviewProps) => {
  const { translate } = useI18n();
  const styles = useStyles();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onFileDialogOpen: () => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });
    },
    onDrop: async (files) => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });

      const file = files[0];

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
          uploadDomain.ui.error.setValue({
            code: 'file-too-small',
            message: translate('phrases.imageIsTooSmall'),
          });
        } else {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          });

          uploadDomain.uploadToFileStore(compressedFile, FileType.IMAGE).then((response) => {
            const { fileUrl, name, size } = response;
            onChange({ data: fileUrl, name, size });
          });
        }
      };
    },
    accept: { [uploadDomain.ui.key.value]: uploadDomain.ui.accept.list },
    maxFiles: uploadDomain.ui.maxFiles.value,
    maxSize: uploadDomain.ui.maxSize.value,
  });

  useEffect(() => {
    const files = acceptedFiles.map((file) => {
      return file;
    });
    if (fileRejections.length !== 0) {
      const errors = fileRejections.map((file: { errors: IErrorUpload[] }) => {
        const error = file.errors[0];
        if (error.code === 'file-too-large') {
          return {
            code: error.code,
            message: translate('uploadImagePreview.imageIsTooBig'),
          };
        } else if (error.code === 'file-invalid-type') {
          return {
            code: error.code,
            message: translate('uploadImagePreview.incorrectImageFormat'),
          };
        }
        return error;
      });
      uploadDomain.ui.error.setValue(errors[0]);
    }
    uploadDomain.ui.files.setList(files);
  }, [acceptedFiles, fileRejections, translate, uploadDomain]);

  return (
    <React.Fragment>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              maxHeight: '284px',
            }}
          >
            {uploadDomain.ui.error.value.code && (
              <Box className={styles.errorWrapper}>
                <ErrorOutline sx={{ color: 'rgba(244, 67, 54, 1)' }} />
                <Typography sx={{ marginRight: 'auto', color: '#fbb4af' }} variant="body2">
                  {uploadDomain.ui.error.value.message}
                </Typography>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    uploadDomain.ui.error.setValue({ code: '', message: '' });
                  }}
                >
                  <CloseOutlined sx={{ color: '#fbb4af' }} />
                </IconButton>
              </Box>
            )}
            <Box className={styles.uploadWrapper}>
              <Box className={styles.uploadContent}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button variant="outlined" className={styles.uploadButton}>
                    <FileUploadOutlined />
                  </Button>
                  <Typography variant="body1">
                    <TranslateText i18nKey="uploadImagePreview.dragAndDropMessage_1" />{' '}
                    <span className={styles.underlineLink}>
                      <TranslateText i18nKey="uploadImagePreview.dragAndDropMessage_2" />
                    </span>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '24px' }}>
                  <ul>
                    <li>
                      <TranslateText i18nKey="uploadImagePreview.aspectRatio" />
                    </li>
                    <li>
                      <TranslateText i18nKey="uploadImagePreview.minSize" />
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <TranslateText i18nKey="uploadImagePreview.acceptableFormats" />
                    </li>
                    <li>
                      <TranslateText i18nKey="uploadImagePreview.backgroundRequirements" />
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </section>
    </React.Fragment>
  );
});
