import { IContainer } from "../../common/container/IContainer";
import { MainLayoutBottomLogoMenu } from "../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu";
import { ErrorPageOrganization, isShowOnlyEmail } from "../../view/layout/main/parts/LeftNavigation/parts/MainLayoutLeftNavigationTopLogo";
import { IExtension } from "../interface/IExtension";
import { templatePath } from "../learning/learningInjects";
import {RSSecurityBottomLogo} from './components/RSSecurityBottomLogo';

export default class RSSecurityExtension implements IExtension {
  async loadExtension(container: IContainer) {
    container = this.setBottomLogo(container)
    container = this.setUnitSummuryTemplatePath(container)
    container = this.setErrorPageOrganization(container)
    container = this.setErropageEmailOnlyContact(container)
  }

  setBottomLogo(container:IContainer):IContainer{
    container.set(MainLayoutBottomLogoMenu,RSSecurityBottomLogo)
    return container
  }
  setErrorPageOrganization(container:IContainer):IContainer{
    container.set(ErrorPageOrganization,'© Human Factor, LLC')
    return container
  }
  setErropageEmailOnlyContact(container:IContainer):IContainer{
    container.set(isShowOnlyEmail,()=>true)
    return container
  }
  setUnitSummuryTemplatePath(container:IContainer):IContainer{
    container.set(templatePath,()=>'/service/report/service/templates/AppSecMentorLearningProjectUnitSummaryByUsers.docx')
    return container
  }
}
