import { inject } from '../../../../../../../common/container/inject';
import { sleep } from '../../../../../../../common/data/fake/sleep';
import { IApplicationModel } from '../../../../../../../service/application/entity/IApplicationModel';
import { IProjectModel } from '../../../../../../../service/project/IProjectModel';
import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { IMainLayoutDomainStore } from '../../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IProjectView } from '../../../../../../../view/user/page/project/info/store/model/IProjectView';
import { ILocalization, ILocalizationToken } from '../../../../../application/language/ILocalization';
import { ILearningRootService, LearningRootServiceToken } from '../../../../../service/LearningRootService';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { IUnitResultModel } from '../../../../../service/unitResult/IUnitResultModel';
import { UserDataLevel } from '../../../../../service/userData/IUserDataModel';
import { IPassingListPageDomain } from './IPassingListPageDomain';
import { IPassingListPageUI } from './IPassingListPageUI';
import { PassingListPageUI } from './PassingListPageUI';

export class PassingListPageDomain implements IPassingListPageDomain {
  public ui: IPassingListPageUI;
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    protected router = inject<IRouterService>(RouterServiceToken),
    protected learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    public i18n: ILocalization = inject<ILocalization>(ILocalizationToken),
  ) {
    this.ui = new PassingListPageUI();
  }
  loadUnitResults: () => void;
  loadData = async (userId?: string) => {
    if (this.ui.isLoading.value) {
      return;
    }

    this.ui.isLoading.setValue(true);
    const id = userId ? userId : this.layoutDomain.ui.activeUser.entity.id;
    const unitResults = await this.learningRootService.unitResult
      .search({
        limit: 100000,
        filter: [{ fieldName: 'userId', type: 'equal', value: id }],
      })
      .catch((err) => {
        if (err.webCode === '500') {
          return this.router.goTo('/serviceNotAvailable');
        }
      });

    const projects = (
      await this.rootService.project.entity.search({
        filter: {
          json: [
            {
              fieldPath: 'rolesMap.data[].userId',
              value: id,
              type: 'equal',
            },
          ],
        },
      })
    ).data;

    const userProjectIds = projects.map((project) => project.id);
    const userProjectRoles = projects.flatMap((project) => (project.rolesMap?.data ?? []).map((role) => role.roleId));

    const allUsersApplications = (
      await this.rootService.application.entity.search({
        limit: 100000,
        filter: { projectId: { in: userProjectIds as string[] } },
      })
    ).data;

    const publishedUnits = await this.learningRootService.unit.search({
      limit: 100000,
      filter: [
        {
          fieldName: 'isPublished',
          type: 'equal',
          value: true,
        },
      ],
    });

    const importantUserUnits = this.determineMandatoryUnits(
      publishedUnits.data,
      allUsersApplications,
      projects,
      userProjectRoles,
      id,
    );

    const unimportantUnitList = publishedUnits.data.filter((unit) => !importantUserUnits.includes(unit));
    this.ui.importantUnitList.setList(importantUserUnits);
    this.ui.unimportantUnitList.setList(unimportantUnitList);
    const combinedUnits = [...importantUserUnits, ...unimportantUnitList];
    this.ui.passingList.setList(combinedUnits);
    const { data: specificationCategoryData } = await this.rootService.specification.category.search({
      fields: ['name', 'id'],
      limit: 100000,
    });
    this.ui.specificationCategory.setList(specificationCategoryData);
    const { data: characteristicData } = await this.rootService.specification.entity.search({
      fields: ['name', 'categoryId'],
      limit: 10000,
    });
    this.ui.characteristic.setList(characteristicData);

    const userUnitResults = (
      await this.learningRootService.unitResult.search({
        limit: 100000,
        filter: [{ fieldName: 'userId', type: 'equal', value: id }],
      })
    ).data.filter((unitResult) => {
      return combinedUnits.some((unit) => unit.id === unitResult.unitId);
    });


    this.ui.passingReslutList.setList(userUnitResults);
    this.getAllPossibleScoreOfUnits();

    this.createdGroupedList();
    this.checkingPassedAmount();
    if (userUnitResults) {
      setTimeout(() => {
        this.ui.isLoading.setValue(false);
      }, 300);
    }
    this.ui.filtredGroupedUnits.setList(this.ui.groupedUnits.list);

    const currentUser = this.layoutDomain.ui.activeUser.entity;

    const applicationsWithUnits = this.getSystemsByUnits(importantUserUnits, allUsersApplications)
      .map((application) => {
        const applicationCopy: any = {
          id: application.id,
          name: application.name,
          specificationsIds: application.specificationsIds,
        };
        const specificationsIds = application.specificationsIds || [];

        const systemProject = projects.find((item) => application.projectId == item.id);
        const projectRole = systemProject?.rolesMap?.data?.find((item) => item.userId === currentUser.id)?.roleId;
        applicationCopy.units = importantUserUnits.filter((unit) => {
          return (
            unit.settings.teamRolesIds.includes(projectRole) &&
            unit.settings.characteristicsIds.some((characteristicId) => specificationsIds.includes(characteristicId))
          );
        });

        applicationCopy.unitResults = applicationCopy.units.map((unit) => {
          return this.ui.passingReslutList.list.find((result) => result.unitId === unit.id) || null;
        });

        return applicationCopy;
      })
      .filter((application) => {
        return application.units.length;
      });

    this.ui.applicationsWithUnits.setList(applicationsWithUnits);
    this.getUserData(id, combinedUnits, unitResults.data);
  };

  getUserDataById = async (id: string) => {
    const userDataSearch = await this.learningRootService.userData.search({
      limit: 100000,
      fields: { include: ['toNextLevelScore', 'level', 'totalScore', 'maxScore'] },
      filter: [
        {
          fieldName: 'userId',
          type: 'equal',
          value: id,
        },
      ],
    });

    return userDataSearch.data.length > 0 ? userDataSearch.data[0] : null;
  }

  getUserData = async (id: string, combinedUnits: IUnitModel[], userUnitResults: IUnitResultModel[]) => {
    let userData = await this.getUserDataById(id);
    if (!userData) {
      try {
        userData = await this.createNewUserDate(id, combinedUnits, userUnitResults)
      } catch {
        // can be race condition
        await sleep(300);
        userData = await this.getUserDataById(id);
      }
    }
    if (!userData) return;
    this.ui.userData.setValue(userData);
  };

  createNewUserDate = async (id: string, combinedUnits: IUnitModel[], userUnitResults: IUnitResultModel[]) => {
    let newMaxScore = 0;
    let newUserTotalScore = 0;
    combinedUnits.forEach((unit) => (newMaxScore += unit.score));
    userUnitResults.forEach((reuslt) => (newUserTotalScore += reuslt.totalUnitResult));
    const level =
      newUserTotalScore <= Math.round((newMaxScore * 35) / 100)
        ? UserDataLevel.JUNIOR
        : Math.round((newMaxScore * 35) / 100) <= newUserTotalScore &&
          newUserTotalScore <= Math.round((newMaxScore * 69) / 100)
          ? UserDataLevel.MIDDLE
          : UserDataLevel.SENIOR;

    const toNextLevelScore =
      level === UserDataLevel.SENIOR
        ? Math.round(newMaxScore - newUserTotalScore)
        : level === UserDataLevel.MIDDLE
          ? Math.round((newMaxScore * 69) / 100 - newUserTotalScore + 1)
          : Math.round((newMaxScore * 35) / 100 - newUserTotalScore + 1);

    const newUserDataId = await this.learningRootService.userData.createByModel({
      userId: id,
      totalScore: newUserTotalScore,
      level,
      maxScore: newMaxScore,
      toNextLevelScore,
    });
    const newUserDataSearchResult = await this.learningRootService.userData.getById(newUserDataId);

    return newUserDataSearchResult;

  }

  getAllPossibleScoreOfUnits = () => {
    let score = 0;
    this.ui.passingList.list.forEach((unit) => {
      score += unit.score;
    });
    this.ui.allUnitsScore.setValue({ allUnitsScore: score, possibleScoreOnePart: Math.round(score / 3) });
  };

  checkingPassedAmount = () => {
    this.ui.groupedUnits.list.forEach((group) => {
      group.importantUnitList.forEach((unit) => {
        for (let i = 0; i < this.ui.passingReslutList.list.length; i++) {
          if (unit.id === this.ui.passingReslutList.list[i].unitId && this.ui.passingReslutList.list[i].isPassed) {
            group.passedAmount += 1;
          }
        }
      });
    });
    this.ui.groupedUnits.list.forEach((group) => {
      group.unimportantUnitList.forEach((unit) => {
        for (let i = 0; i < this.ui.passingReslutList.list.length; i++) {
          if (unit.id === this.ui.passingReslutList.list[i].unitId && this.ui.passingReslutList.list[i].isPassed) {
            group.passedAmount += 1;
          }
        }
      });
    });
  };

  createdGroupedList = () => {
    const characteristicToSpecName = {};
    this.ui.characteristic.list.forEach((char) => {
      const spec = this.ui.specificationCategory.list.find((spec) => spec.id === char.categoryId);
      if (spec) {
        characteristicToSpecName[char.id] = spec.name;
      }
    });

    const groupedUnits = {};
    const allUnits = [...this.ui.importantUnitList.list, ...this.ui.unimportantUnitList.list];
    allUnits.forEach((unit) => {
      const charIds = unit.settings.characteristicsIds;
      if (charIds && charIds.length > 0) {
        const charId = charIds[0];
        const groupName = characteristicToSpecName[charId] || charId;
        groupedUnits[groupName] = groupedUnits[groupName] || [];
        groupedUnits[groupName].push(unit);
      } else {
        const groupName = this.i18n.translate('learningAdminPage.withoutContext');
        groupedUnits[groupName] = groupedUnits[groupName] || [];
        groupedUnits[groupName].push(unit);
      }
    });

    const sortedGroupNames = Object.keys(groupedUnits).sort();

    this.ui.groupedUnits.setList(
      sortedGroupNames.map((groupName) => ({
        groupName,
        importantUnitList: groupedUnits[groupName].filter((unit) => this.ui.importantUnitList.list.includes(unit)),
        unimportantUnitList: groupedUnits[groupName].filter((unit) => !this.ui.importantUnitList.list.includes(unit)),
        passedAmount: 0,
        passingPercent: 0,
      })),
    );
    this.calculatePassingPercentages();
  };

  calculatePassingPercentages = () => {
    this.ui.groupedUnits.list.forEach((group) => {
      const totalUnits = group.importantUnitList.length + group.unimportantUnitList.length;
      const passedUnits = this.calculatePassedUnits(group);
      group.passingPercent = Math.round((passedUnits / totalUnits) * 100);
    });
  };

  calculatePassedUnits = (group) => {
    let passedAmount = 0;
    [...group.importantUnitList, ...group.unimportantUnitList].forEach((unit) => {
      if (this.ui.passingReslutList.list.some((result) => result.unitId === unit.id && result.isPassed)) {
        passedAmount++;
      }
    });
    return passedAmount;
  };

  redirect = async (id: string) => {
    !this.ui.isAdminMode.value && this.router.goTo(`/learning/${id}`);
  };

  handleSearchByGroupsAndName = (searchText: string) => {
    const trimmedSearchText = searchText.trim();
    const filteredGroups = this.ui.groupedUnits.list
      .map((group) => {
        const isGroupNameMatch = group.groupName.toLowerCase().includes(trimmedSearchText.toLowerCase());

        const filterUnitsBySearchText = (unitList: any[]) => {
          return unitList.filter((unit) =>
            unit.settings.titleHeading.toLowerCase().includes(trimmedSearchText.toLowerCase()),
          );
        };

        const filteredImportantUnits = filterUnitsBySearchText(group.importantUnitList);
        const filteredUnimportantUnits = filterUnitsBySearchText(group.unimportantUnitList);

        return {
          ...group,
          importantUnitList: isGroupNameMatch ? group.importantUnitList : filteredImportantUnits,
          unimportantUnitList: isGroupNameMatch ? group.unimportantUnitList : filteredUnimportantUnits,
        };
      })
      .filter((group) => group.importantUnitList.length > 0 || group.unimportantUnitList.length > 0);

    this.ui.filtredGroupedUnits.setList(filteredGroups);
    this.calculatePassingPercentages();
  };

  //TODO:  [{id: 1, isPassed: true}, {id: 2, isPassed: true}, {id: 1, isPassed: null}, {id: 2, isPassed: null}] -> [{id: 1, isPassed: true}, {id: 2, isPassed: true}]
  filterUnitResultsDoubles = (arr: IUnitResultModel[]): IUnitResultModel[] => {
    const result: { [key: number]: IUnitResultModel } = {};

    arr.forEach(item => {
      if (!result[item.unitId] || (item.isPassed !== null && result[item.unitId].isPassed === null)) {
        result[item.unitId] = item;
      }
    });

    return Object.values(result);
  };

  //TODO: перенести в отдельный домен
  loadImportantUnits = async (projects: IProjectView[]) => {
    this.ui.isProjectsWithUserStatsLoading.setValue(true);
    const publishedUnits = (
      await this.learningRootService.unit.search({
        limit: 100000,
        filter: [
          {
            fieldName: 'isPublished',
            type: 'equal',
            value: true,
          },
        ],
      })
    ).data;

    const projectsFullData = (
      await this.rootService.project.entity.search({
        filter: {},
      })
    ).data;

    const allApplications = (
      await this.rootService.application.entity.search({
        limit: 100000,
        filter: {},
      })
    ).data;

    const unitsResults = (
      await this.learningRootService.unitResult.search({
        limit: 100000,
      })
    ).data;

    //Обязательные юниты
    const projectsImportantUnitsMap = this.determineMandatoryUnitsStats({
      projects,
      projectsFullData,
      allApplications,
      publishedUnits,
    });

    const projectStats = projectsImportantUnitsMap.map((prj) => {
      return {
        ...prj,
        users: prj.project.team.map((user) => {
          const roleId = prj.project.rolesMap?.data?.find((item) => item.userId === user.id)?.roleId;

          //Оставляем только обязательные юниты в unitsResults
          const importantUnitsResults = unitsResults.filter((unitResult) =>
            prj.importantUnitsIds.has(unitResult.unitId),
          );

          //Мапим их с данными settings - rolesId
          const importantUnitsResultsWithRoleIds = importantUnitsResults.map((item) => ({
            ...item,
            settings: publishedUnits.find((unit) => unit?.id === item.unitId)?.settings,
          }));

          //Оставляем только результаты обязательных юнитов текущего юзера
          const currUserResults = importantUnitsResultsWithRoleIds.filter((unitResult) => {
            //Юнит считается обязательным, если он текущему юзеру и текущий юзер есть в списке teamRolesIds
            return unitResult.userId === user.id && unitResult.settings?.teamRolesIds.includes(roleId || '');
          });

          //Юниты, обязательные для юзера, но еще не пройденные им
          const requiredForUserUnits = prj.importantUnits.filter((unit) =>
            unit.settings.teamRolesIds.some((id) => id === roleId),
          );

          return { user, userId: user.id, unitResults: this.filterUnitResultsDoubles(currUserResults), requiredForUserUnits };
        }),
      };
    });

    this.ui.projectsWithUserStats.setList(projectStats);
    this.ui.isProjectsWithUserStatsLoading.setValue(false);
  };

  determineMandatoryUnits = (units, applications, projects, userProjectRoles, userId) => {
    return units.filter((unit) => {
      return applications.some((application) => {
        // Проверяем, связан ли проект пользователя с приложением
        const userProject = projects.find((project) => project.id === application.projectId);

        // Проверяем, имеет ли пользователь в проекте роль, совпадающую с ролью в настройках юнита
        const isUserInProjectRole = userProject.rolesMap.data.some(
          (role) => role.userId === userId && userProjectRoles.includes(role.roleId),
        );

        // Проверяем, соответствует ли роль пользователя в проекте какой-либо из ролей, необходимых для юнита
        const hasMatchingRole = unit.settings.teamRolesIds.some((unitRole) =>
          userProject.rolesMap.data.some(
            (projectRole) => projectRole.userId === userId && projectRole.roleId === unitRole,
          ),
        );

        // Проверяем совпадение характеристик юнита и приложения
        const hasMatchingCharacteristic = unit.settings.characteristicsIds.some((characteristic) =>
          application.specificationsIds.includes(characteristic),
        );

        return isUserInProjectRole && hasMatchingRole && hasMatchingCharacteristic;
      });
    });
  };

  getSystemsByUnits = (importantUserUnits, allUsersApplications) => {
    const systems: IApplicationModel[] = [];
    importantUserUnits.forEach((unit) => {
      allUsersApplications.forEach((item) => {
        if (
          unit.settings.characteristicsIds.some((characteristic) => item.specificationsIds?.includes(characteristic)) &&
          !systems.some((system) => system.id === item.id)
        ) {
          systems.push(item);
        }
      });
    });

    return systems;
  };

  determineMandatoryUnitsStats = ({
    projects,
    projectsFullData,
    allApplications,
    publishedUnits,
  }: {
    projects: IProjectView[];
    projectsFullData: IProjectModel[];
    allApplications: IApplicationModel[];
    publishedUnits: IUnitModel[];
  }) => {
    let projectsImportantUnitsMap: {
      project: IProjectView & IProjectModel;
      importantUnits: IUnitModel[];
      importantUnitsIds: Set<string>;
    }[] = [];
    projects
      .filter((prj) => prj.team.length)
      .forEach((prj) => {
        const currPrjFullData = projectsFullData.find((item) => item.id === prj.id);
        const currPrjApplications = allApplications.filter((item) => item.projectId === prj.id);
        //Характеристики текущего проекта
        const currPrjSpecifications = new Set(currPrjApplications.flatMap((item) => item.specificationsIds));

        //Юниты с характеристиками (characteristicsIds) проекта
        const currPrjUnits = publishedUnits.filter((item) =>
          item.settings.characteristicsIds.some((charId) => currPrjSpecifications.has(charId)),
        );

        //Айдишники (project)roleId
        const currPrjRoles = new Set(currPrjFullData?.rolesMap?.data?.map((item) => item.roleId));

        //Юниты currPrjUnits, которые связаны с ролями из текущего проекта currPrjFullData
        const currPrjRolesUnits = currPrjUnits.filter((unit) =>
          unit.settings.teamRolesIds.some((roleId) => currPrjRoles.has(roleId)),
        );

        projectsImportantUnitsMap.push({
          project: { ...prj, ...currPrjFullData },
          importantUnits: currPrjRolesUnits,
          importantUnitsIds: new Set(currPrjRolesUnits.map((item) => item.id)),
        });
      });

    return projectsImportantUnitsMap;
  };
}
