import { useEffect, useState } from 'react';

import { ConstructorType, injectValue } from '../../../../../../common/container/inject';
import { ProjectInfoDomain } from '../../../../../../view/user/page/project/info/store/ProjectInfoDomain';
import { IPassingListPageDomain } from '../../../admin/page/passingListPage/store/IPassingListPageDomain';
import { IProjectsWithUserStats } from '../../../admin/page/passingListPage/store/IProjectsWithUserStats';
import { PassingListPageDomain } from '../../../admin/page/passingListPage/store/PassingListPageDomain';
import { useDeferredCallback } from '../../../shared/hooks/useDeferredCallback';

export const useGetProjectsWithUnitsStats = (layoutDomain) => {
  //@ts-ignore
  const [passingDomain] = useState<IPassingListPageDomain>(new PassingListPageDomain(layoutDomain));
  const [projectInfoDomain] = useState<ProjectInfoDomain>(
    new (injectValue<ConstructorType<ProjectInfoDomain>>(ProjectInfoDomain))(layoutDomain),
  );
  const [projectsWithUnitsStats, setProjectsWithUnitsStats] = useState<IProjectsWithUserStats[]>([]);

  useDeferredCallback(() => {
    projectInfoDomain.loadData();
  }, [projectInfoDomain, passingDomain]);

  useEffect(() => {
    if (
      !(projectInfoDomain.ui.projects.list.length === 1 && projectInfoDomain.ui.projects.list.some((item) => !item.id))
    ) {
      passingDomain?.loadImportantUnits?.(projectInfoDomain.ui.projects.list);
    }
  }, [passingDomain, projectInfoDomain.ui.projects.list]);

  useEffect(() => {
    setProjectsWithUnitsStats(passingDomain.ui.projectsWithUserStats?.list);
  }, [passingDomain.ui.projectsWithUserStats?.list]);

  return { projectsWithUnitsStats, isProjectsWithUserStatsLoading: passingDomain.ui.isProjectsWithUserStatsLoading };
};
