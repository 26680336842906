import { IProjectsWithUserStats } from '../../../admin/page/passingListPage/store/IProjectsWithUserStats';

export interface IProjectToUserStatisticsMap {
  project: { id: string; name: string };
  users: {
    usersNotPassedStudying: number;
    usersNotStartedStudying: number;
    usersPassedStudying: number;
  };
}

export const getProjectToUserStatisticsMap = (projects: IProjectsWithUserStats[]) => {
  const projectToUserStatisticsMap: IProjectToUserStatisticsMap[] = [];
  projects?.forEach((project) => {
    let usersPassedStudying = 0;
    let usersNotPassedStudying = 0;
    let usersNotStartedStudying = 0;

    //Если на проекте нет обязательных юнитов, то его не отображаем
    if (!project.importantUnitsIds.size) {
      return;
    }


    project.users.forEach((userData) => {
      //Если у юзера есть обязательные юниты, то распределяем их по категориям
      if(userData.requiredForUserUnits.length === 0) return;

      const countsOfUserResults = userData.unitResults.reduce((prev, { isPassed }) => prev + (isPassed === null ? 0 : 1), 0);

      const setOfRequred = new Set(userData.requiredForUserUnits.map(({ id }) => id));

      userData.unitResults.forEach(({ unitId, isPassed }) => {
        if (isPassed) {
          setOfRequred.delete(unitId)
        }
      });

      if (setOfRequred.size === 0) {
        usersPassedStudying++;
      } else if (countsOfUserResults === 0 || setOfRequred.size === countsOfUserResults) {
        usersNotStartedStudying++;
      } else {
        usersNotPassedStudying++;
      }
    });

    projectToUserStatisticsMap.push({
      project: { ...project.project },
      users: { usersPassedStudying, usersNotPassedStudying, usersNotStartedStudying },
    });
  });

  return { projectToUserStatisticsMap };
};
