import { RestCRUDService } from '../common/rest/RestCRUDService';
import { CRUDServiceType } from '../common/service/CRUDServiceType';
import { IUserEntityModel } from './IUserEntityModel';
import { IUserEntitySearch } from './IUserEntitySearch';
import { IUserEntityService } from './IUserEntityService';
import { UserSourceType } from './UserSourceType';

export class RestUserEntityService
  extends RestCRUDService<IUserEntityModel, IUserEntitySearch>
  implements IUserEntityService
{
  private sourceTypeTranslateMap = {
    [UserSourceType.activeDirectory]: 'Active Directory',
    [UserSourceType.internal]: 'Локальный',
  };

  constructor(private type: CRUDServiceType) {
    super();
  }

  translateSourceType(type: UserSourceType): string {
    return this.sourceTypeTranslateMap[type] || 'неизвестный тип';
  }

  async getUserLoginsAndRoles(): Promise<any[]> {
    return await fetch(this.getEndpoint() + '/loginsAndRoles', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('jwtToken')}`
      }
    }).then((res) => res.json());
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/user`;
  }
}
