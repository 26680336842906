import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useI18n } from '../../../../../application/language/useI18n';
import { UserRating } from '../../admin/page/usersRating/UserRating';
import space from './assets/space.png';
import { ILearningApplicationDetailDomain } from './domain/ILearningApplicationDetailDomain';
import { ApplicationInfoWarning } from './parts/ApplicationInfoWarning';
import { UnitListAdminCard } from './parts/UnitListAdminCard';
import { UnitListUserCard } from './parts/UnitListUserCard';

export const LearningApplicationInfoPage = observer(({ domain }: { domain: ILearningApplicationDetailDomain }) => {
  const { applicationId = '' } = useParams<any>();
  const { translate } = useI18n();

  useEffect(() => {
    domain.loadLearning(applicationId);

    return () => {
      domain.dropFilterByRole();
    };
  }, [applicationId, domain]);

  const renderUserUnits = (group) =>
    group.unitsList.required.map((unit) => {
      const unitResult = domain.ui.passingResultList.list.filter((unitRes) => unitRes.unitId === unit.id)[0] || {
        totalUnitResult: 0,
        isPassed: false,
      };
      return (
        <UnitListUserCard
          key={unit.id}
          card={unit}
          action={domain.redirect}
          score={unit.score}
          passingUnitResult={unitResult}
        />
      );
    });

  const UserNoUnitsComponents = () => (
    <Box width={'100%'} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box width={'auto'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '8px' }}>
        <img src="/pictures/sphere-nexus.png" />
        <Typography textAlign={'center'} typography={'h6'} marginTop="16px">
          {translate('phrases.trainingNotSetYet')}
        </Typography>
        <Typography
          textAlign={'center'}
          typography="body2"
          marginTop="8px"
          marginBottom="16px"
          maxWidth="300px"
          fontSize="12px"
          sx={{ opacity: 0.7 }}
        >
          {translate('phrases.noTrainingForThisSystem')}
        </Typography>
        <a href="/learning/user">
          <Button
            sx={{
              background: '#90CAF9',
              color: '#000000',
              '&:hover': {
                backgroundColor: '#74CEF9',
              },
            }}
          >
            {translate('phrases.toLearning')}
          </Button>
        </a>
      </Box>
    </Box>
  );

  const renderAdminUnits = (group, type) => {
    if (!group.unitsList[type].length) {
      return null;
    }

    return (
      <Box sx={{ marginBottom: '16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ marginBottom: '16px', color: 'rgba(255, 255, 255, 0.5)' }}>
            {translate(type === 'required' ? 'phrases.mandatory' : 'phrases.optional')}
          </Typography>
          <Box
            sx={{
              width: '32px',
              height: '24px',
              padding: '4px, 6px, 4px, 6px',
              borderRadius: '32px',
              border: 'solid 1px rgba(255, 255, 255, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              color: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            {group.unitsList[type].length}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {group.unitsList[type].map((unit) => (
            <UnitListAdminCard
              key={unit.id}
              isRequired={type === 'required'}
              unit={unit}
              applicationId={applicationId}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const showNoUnitsRecommended =
    !domain.ui.isAdmin.value && !domain.ui.groupedUnits.list.some((group) => group.unitsList.required.length > 0);

  return (
    <>
      {!domain.ui.isLoading.value && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '64px', padding: '24px 15px' }}>
          {domain.ui.unitList.list.length > 0 ? (
            <>
              <Typography variant="h4">
                {translate(`phrases.${domain.ui.isAdmin.value ? 'teamCompetencyMap' : 'competencyMap'}`)}
              </Typography>
              {domain.ui.isAdmin.value && (
                <>
                  {domain.ui.rolesWithUnits.list.length !== 0 ? (
                    <Box sx={{ display: 'flex', marginTop: '16px', flexWrap: 'wrap' }}>
                      {domain.ui.rolesWithUnits.list
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((role) => (
                          <Box
                            key={role.roleId}
                            onClick={() => domain.filterByRole(role.roleId)}
                            sx={{
                              height: '28px',
                              padding: '4px 12px',
                              marginLeft: '10px',
                              borderRadius: '48px',
                              backgroundColor: domain.ui.roleFilterIds.list.includes(role.roleId)
                                ? 'rgba(33, 150, 243, 1)'
                                : 'rgba(255, 255, 255, 0.12)',
                              color: 'rgba(255, 255, 255, 1)',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '12px',
                              cursor: 'pointer',
                              '&:first-of-type': {
                                marginLeft: 0,
                              },
                            }}
                          >
                            {role.name}
                          </Box>
                        ))}
                    </Box>
                  ) : (
                    <ApplicationInfoWarning
                      warningText={translate('phrases.noMandatoryUnitsForTheRole')}
                      description={translate('phrases.assignUnitsMessage')}
                      btnText={translate('phrases.setupProjectRoles')}
                      redirectTo={() => domain.redirectTo('/settings/user/project/role/entity')}
                    />
                  )}
                  {domain.ui.isShowWarning.value && (
                    <ApplicationInfoWarning
                      warningText={translate('phrases.profileCharacteristicsErrorMessage1')}
                      description={translate('phrases.profileCharacteristicsErrorMessage2')}
                      btnText={translate('phrases.fillOutTheProfile')}
                      redirectTo={() => (domain.ui.tabsData.value.state = 0)}
                    />
                  )}
                </>
              )}
              <Box
                sx={{
                  display: showNoUnitsRecommended ? 'block' : 'grid',
                  gridTemplateColumns: 'repeat(3,1fr)',
                  columnGap: '24px',
                  rowGap: '8px',
                  marginTop: '24px',
                }}
              >
                {domain.ui.groupedUnits.list.length > 0
                  ? domain.ui.groupedUnits.list.map((group) => {
                      const hasUnits = group.unitsList.required.length > 0 || group.unitsList.notRequired.length > 0;
                      const shouldShowNotRequiredUnitsToAdmin =
                        domain.ui.roleFilterIds.list.length > 0 && !group.unitsList.required.length;
                      const shouldShowCardToUser = !domain.ui.isAdmin.value && !group.unitsList.required.length;
                      const filterPicked = !!domain.ui.roleFilterIds.list.length;

                      if (!hasUnits || shouldShowNotRequiredUnitsToAdmin || shouldShowCardToUser) {
                        return null;
                      }

                      return (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }} key={group.groupName}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ marginBottom: '16px', fontSize: '20px' }}>{group.groupName}</Typography>
                            <Box
                              sx={{
                                width: '32px',
                                height: '24px',
                                padding: '4px, 6px, 4px, 6px',
                                borderRadius: '32px',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'rgba(255, 255, 255, 0.7)',
                              }}
                            >
                              {filterPicked
                                ? group.unitsList.required.length
                                : group.unitsList.required.concat(group.unitsList.notRequired).length}
                            </Box>
                          </Box>

                          {domain.ui.isAdmin.value ? (
                            <>
                              {renderAdminUnits(group, 'required')}
                              {!filterPicked && renderAdminUnits(group, 'notRequired')}
                            </>
                          ) : group.unitsList.required.length > 0 ? (
                            renderUserUnits(group)
                          ) : null}
                        </Box>
                      );
                    })
                  : null}
                {showNoUnitsRecommended && <UserNoUnitsComponents />}
              </Box>
            </>
          ) : (
            <NoUnitsDisplay isAdmin={domain.ui.isAdmin.value} domain={domain} />
          )}
          {domain.ui.isAdmin.value && <TeamDisplay domain={domain} />}
        </Box>
      )}
    </>
  );
});

const NoUnitsDisplay = ({ isAdmin, domain }) => {
  const { translate } = useI18n();

  return <Box sx={{ padding: '24px 16px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
    <Typography variant="h4">{translate(isAdmin ? 'phrases.teamCompetencyMap' : 'userCardInfo.noAssignedUnits')}</Typography>
    {isAdmin && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '24px 0px',
          gap: '24px',
        }}
      >
        <img src={space} alt="space" />
        <Typography variant="h5">Нет подходящих юнитов</Typography>
        <Button
          size="large"
          variant="contained"
          sx={{ background: '#90CAF9' }}
          onClick={() => domain.redirectToKnowledge()}
        >
          Перейти в Знания
        </Button>
      </Box>
    )}
  </Box>
};

const TeamDisplay = ({ domain }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
    <Typography variant="h4">Команда</Typography>
    <UserRating includedTeam={domain.ui.teamUserIds.list} layoutDomain={domain.layoutDomain} />
  </Box>
);
