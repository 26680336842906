import React from 'react';
import { IApplicationAdditionalRoutes } from '../../../application/ApplicationAdditionalRoutes';
import EmptyLayoutRoute from '../../../view/layout/empty/EmptyLayoutRoute';
import { VKOAUTHRedirectPage } from '../view/user/page/login/VKOAUTHRedirectPage';
import { VKRoutes } from './VKRoutes';

export const VKRouter: IApplicationAdditionalRoutes[] = [
  {
    key: VKRoutes.OAUTHRedirect,
    Component: <EmptyLayoutRoute exact path={VKRoutes.OAUTHRedirect} component={VKOAUTHRedirectPage} key={VKRoutes.OAUTHRedirect} />
  },
]