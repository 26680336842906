import Editor, { DiffEditor } from '@monaco-editor/react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { IFileOption, ITask } from '../../../../../service/unit/interface/IUnitPractice';
import { ITasksQuizResult } from '../../../../../service/unitResult/IUnitResultModel';
import { UiButton } from '../../../../ui/UiButton';
import { IFile } from '../fileTree/IFileTree';
import { useI18n } from '../../../../../../../application/language/useI18n';

export interface Props {
  file: IFile;
  lang: string;
  currentOption: IFileOption;
  isExpand: boolean;
  pickingOption: (option: IFileOption) => void;
  addAnswer: (task: ITask, option: string, pickedLang: string, state: boolean, file: IFile) => void;
  setCorrectOptionForPickedLanguage: (task: ITask, pickedLang: string, optionName: string, isChecked: boolean) => void;
  getOption: (option: string) => void;
  task: ITask;
  currentAnswer: ITasksQuizResult;
  canCheck: boolean;
  height: number;
}

export const PassingReview = observer(
  ({
    file,
    lang,
    currentOption,
    isExpand,
    pickingOption,
    getOption,
    addAnswer,
    setCorrectOptionForPickedLanguage,
    task,
    currentAnswer,
    canCheck,
  }: Props) => {
    const {translate} = useI18n();
    const classes = useStyles();

    const submitContainerClasses = classNames(
      classes.headerFileNameRightSubmitContainer,
      currentOption !== null ? classes.visible : classes.hidden,
    );
    const containerRef = useRef<HTMLDivElement>(null);
    const [editorHeight, setEditorHeight] = useState<number>(0);

    useEffect(() => {
      if (!currentOption) {
        pickingOption(file?.options?.[0] as IFileOption);
      }
    }, [currentOption, file?.options, pickingOption]);

    useEffect(() => {
      if (containerRef.current) {
        setEditorHeight(containerRef.current.clientHeight);
      }
    }, [isExpand]);
    return (
      <div className={classes.codeBlocksContainer}>
        {file !== null && !file?.hasOwnProperty('children') && (
          <div className={classes.codeBlocksHeader}>
            <div className={classes.headerFileNameLeftContainer}>{file?.name}</div>
            {file !== null && file?.isQuestion && (
              <Box className={classes.headerFileNameRightContainer}>
                <div className={classes.headerFileNameRightButtons}>
                  {file?.options?.map((option) => (
                    <UiButton
                      size="small"
                      style={option.name === currentOption?.name ? 'primary' : 'secondary'}
                      onClick={() => {
                        pickingOption(option);
                      }}
                    >
                      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <span>{option.name}</span>
                        {/*TODO: Uncomment when practice flow reviewed
                         <Checkbox
                          style={{ padding: '0', color: option.name === currentOption?.name ? '#000' : '#FFF' }}
                          checked={option.name === currentOption?.name}
                        /> */}
                      </div>
                    </UiButton>
                  ))}
                </div>
                <div className={classes.headerFileNameRightActions}>
                  <Typography className={classes.headerFileNameRightTitle}>{file?.name}</Typography>
                  {currentOption !== null && (
                    <Box id="submitCodeAnswer" className={submitContainerClasses}>
                      <FormControlLabel
                        className={classes.submitControl}
                        label={translate('phrases.correct')}
                        control={
                          <Checkbox
                            disabled={!canCheck}
                            className={classes.submitCheckbox}
                            checked={
                              !canCheck
                                ? currentAnswer?.correctOption.includes(currentOption.name)
                                : currentOption.isCorrect
                            }
                            onChange={(event) => {
                              currentOption.isCorrect = event.target.checked;

                              if (event.target.checked) {
                                getOption(currentOption.name);
                              }

                              addAnswer(task, currentOption.name, lang, event.target.checked, file);
                              setCorrectOptionForPickedLanguage(task, lang, currentOption.name, event.target.checked);
                            }}
                          />
                        }
                      />
                    </Box>
                  )}
                </div>
              </Box>
            )}
          </div>
        )}
        <Box className={classes.editorContainer} ref={containerRef}>
          {file !== null && file?.isQuestion && (
            <DiffEditor
              originalLanguage={lang}
              modifiedLanguage={lang}
              theme="vs-dark"
              height={isExpand ? '100%' : '322px'}
              original={file !== null ? file?.value : ''}
              modified={currentOption !== null ? currentOption.value : ''}
              options={{ minimap: { enabled: false }, readOnly: true }}
            />
          )}
          {file !== null && !file?.isQuestion && !file?.hasOwnProperty('children') && (
            <Editor
              value={file?.value}
              language={lang}
              theme="vs-dark"
              height={isExpand ? `${editorHeight}px` : '347px'}
              options={{ minimap: { enabled: false }, readOnly: true }}
            />
          )}
        </Box>
      </div>
    );
  },
);

const useStyles = makeStyles(() => ({
  codeBlocksContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  codeBlocksHeader: { display: 'flex', borderBottom: '1px solid rgba(255,255,255,0.12)' },
  headerFileNameLeftContainer: {
    padding: '4px 0px 32px 8px',
    flex: 1,
    borderRight: '1px solid rgba(255,255,255,0.12)',
  },
  headerFileNameRightContainer: { flex: 1, display: 'flex', flexDirection: 'column' },
  headerFileNameRightTitle: { padding: '4px 0px 4px 8px' },
  headerFileNameRightActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderTop: '1px solid rgba(255,255,255,0.12)',
    padding: '4px',
  },
  headerFileNameRightSubmitContainer: {
    display: 'flex',
    gap: '33px',
    marginLeft: 'auto',
  },
  headerFileNameRightButtons: { display: 'flex', flexWrap: 'wrap', gap: '8px', padding: '8px' },
  visible: { visibility: 'visible' },
  hidden: { visibility: 'hidden' },
  submitControl: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitCheckbox: { maxWidth: '20px', maxHeight: '20px', marginRight: '8px' },
  editorContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));
