import { inject } from '../../../../common/container/inject';
import { IRestService, RestServiceToken } from "../../../../service/common/rest/IRestService";
import { JWT_TOKEN_NAME } from "../../../../service/public/activeUser/JWT_TOKEN_NAME";
import { IOAUTHService } from "./IOAUTHService";
import { IVKAccessModel } from "./IVKAccessModel";

export class RestOAUTHService implements IOAUTHService {
  private jwtStorageName = JWT_TOKEN_NAME;

  constructor(protected restService: IRestService = inject<IRestService>(RestServiceToken)) {}

  async auth(arg: IVKAccessModel): Promise<void> {
    const result = await this.restService.post<IVKAccessModel, any>(this.makeUrl('vk/sys/login'), arg)

    localStorage.setItem(this.jwtStorageName, result.jwt);
  };

  protected makeUrl(uri: string) {
    const domain = this.restService.getConfig().url.api.public;
    return `${domain}/${uri}`;
  }
}
