import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { IEntityListStore } from '../../../../../common/store/interface/IEntityListStore';
import { IPrimitiveStore } from '../../../../../common/store/interface/IPrimitiveStore';
import { ISpecificationCategoryModel } from '../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntityModel } from '../../../../../service/specification/entity/ISpecificationEntityModel';
import { RequirementEntityAdminDomain } from '../../../../admin/page/requirement/newEntity/store/RequirementEntityAdminDomain';
import { FilterTitle } from './FilterTitle';

interface SpecificationsFilterProperties {
  specifications: IEntityListStore<ISpecificationEntityModel>;
  specificationsCategories: IEntityListStore<ISpecificationCategoryModel>;
  searchSpecificationsIds: IPrimitiveStore<string[]>;
  setNewValue: (id: string, checked: boolean) => void;
  rootDomain?: any;
}
export const SpecificationsFilter = observer(
  ({
    specifications,
    specificationsCategories,
    searchSpecificationsIds,
    setNewValue,
    rootDomain
  }: SpecificationsFilterProperties) => {
    const currentSpecificationsArray: ISpecificationEntityModel[] =
      rootDomain?.filterDomain.filteredSpecifications
        && rootDomain?.filterDomain.filteredSpecifications.value !== null
        ? rootDomain?.filterDomain.filteredSpecifications.value
        : specifications.list

    const onlyValueSpecifications = currentSpecificationsArray.filter(spec => !spec.applicationId)
    return (
      <Accordion TransitionProps={{ unmountOnExit: true }} style={{ boxShadow: "none", position: 'inherit' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FilterTitle selectedCount={searchSpecificationsIds.value.length} title={'Характеристики'} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container={!(rootDomain instanceof RequirementEntityAdminDomain)}>
            {onlyValueSpecifications.length ?
              specificationsCategories.list.map((specificationCategory) => {
                const categorySpecifications = onlyValueSpecifications.filter(
                  (specification) => specification.categoryId === specificationCategory.id,
                );
                if (categorySpecifications.length !== 0) {
                  return (
                    <SpecificationCategory
                      key={specificationCategory.id}
                      category={specificationCategory}
                      categorySpecifications={categorySpecifications}
                      searchSpecificationsIds={searchSpecificationsIds.value}
                      setNewValue={setNewValue}
                    />
                  );
                }
              })
              : <div>Характеристики не найдены</div>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
);

interface StatusCategoryProperties {
  category: ISpecificationCategoryModel;
  categorySpecifications: ISpecificationEntityModel[];
  searchSpecificationsIds: string[];
  setNewValue: (id: string, checked: boolean) => void;
}

const SpecificationCategory = observer(
  ({ category, categorySpecifications, searchSpecificationsIds, setNewValue }: StatusCategoryProperties) => {
    return (
      <div>
        <FormControl component="fieldset" margin='none'>
          <FormLabel
            color="secondary"
            component="legend"
            style={{ marginBottom: 10 }}
          >
            {category.name}
          </FormLabel>
          <FormGroup
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {categorySpecifications.map((specification) => {
              const isChecked = searchSpecificationsIds.includes(specification.id || '');
              return (
                <Specification
                  key={specification.id}
                  specification={specification}
                  setNewValue={setNewValue}
                  isChecked={isChecked}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
    );
  },
);

interface SpecificationProperties {
  setNewValue: (id: string, checked: boolean) => void;
  specification: ISpecificationEntityModel;
  isChecked: boolean;
}

const Specification = observer(({ specification, isChecked, setNewValue }: SpecificationProperties) => {
  return (
    <FormControlLabel
      style={{ marginBottom: 15 }}
      control={<Checkbox checked={isChecked} name={specification.name} />}
      onChange={(event: any) => {
        setNewValue(specification.id || '', event.target.checked);
      }}
      label={specification.name || ''}
    />
  );
});
