import { Close } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { TranslateText } from '../../../../../application/language/TranslateText';
import { ICommonProperties } from '../../../../../common/properties/ICommonProperties';
import { FileType } from '../../../../../service/fileStore/IFileStoreService';
import { IFileUploadDomain } from './store/IFileUploadDomain';

export interface IFileUpload extends ICommonProperties {
  uploadDomain: IFileUploadDomain;
  onChange: (newData: string) => void;
  fileType: FileType;
}
export const FileUpload = observer(({ uploadDomain, onChange, fileType }: IFileUpload) => {
  const onFileUploadChange = (newData: string) => {
    onChange(newData);
  };
  const classes = useStyles();
  const [serverRejectsFiles, setServerRejectsFiles] = useState<FileRejection[]>([]);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onFileDialogOpen: () => {
      uploadDomain.ui.error.setValue({ code: '', message: '' });
    },
    onDrop: async (files) => {
      const [file] = files;
      uploadDomain.ui.error.setValue({ code: '', message: '' });
      await uploadDomain.uploadToFileStore(file, fileType).then(({ fileUrl }) => {

        onFileUploadChange(fileUrl);
      }).catch(err => {
        setServerRejectsFiles((prev => ([...prev, {
          file,
          errors: [err]
        }])))
        uploadDomain.ui.error.setValue(err)
      });
    },
    accept: { [uploadDomain.ui.key.value]: uploadDomain.ui.accept.list },
    maxFiles: uploadDomain.ui.maxFiles.value,
    maxSize: uploadDomain.ui.maxSize.value,
  });

  useEffect(() => {
    const allRejected = [...fileRejections, ...serverRejectsFiles];
    const allRejectedSet = new Set(allRejected.map(({ file }) => file));

    const files = acceptedFiles.map((file) => {
      return file;
    }).filter((file) => !allRejectedSet.has(file));

    if (allRejected.length !== 0) {
      const errors = allRejected.map((fileErr) => {
        return fileErr.errors[0];
      });
      uploadDomain.ui.error.setValue(errors[0]);
    }
    uploadDomain.ui.files.setList(files);
  }, [acceptedFiles, fileRejections, serverRejectsFiles, uploadDomain]);

  return (
    <React.Fragment>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Box
            sx={{
              padding: '16px 12px',
              borderRadius: '8px',
              border: `2px dashed ${uploadDomain.ui.error.value.code === '' ? 'rgba(0, 0, 0, 0.12)' : '#AD7C6E'} `,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              background: 'rgba(0, 0, 0, 0.04)',
            }}
          >
            <Typography>
              <TranslateText i18nKey='fileUpload.drag' />
              <label className={classes.blueSpan}>
                <TranslateText i18nKey='fileUpload.chooseFile' />
              </label>
            </Typography>
            <Typography>
              <TranslateText i18nKey='fileUpload.uploadRules' options={{
                list: uploadDomain.uppercaseFormater(uploadDomain.ui.accept.list), maxSize: Math.ceil(
                  uploadDomain.ui.maxSize.value / 1024 / 1000)
              }} />
            </Typography>
          </Box>
          {uploadDomain.ui.error.value.code === 'file-too-large' && (
            <Typography color="error" sx={{ fontSize: '12px', padding: '8px' }}>
              <TranslateText i18nKey='fileUpload.limitError' />
            </Typography>
          )}
          {uploadDomain.ui.error.value.code === 'file-invalid-type' && (
            <Typography color="error" sx={{ fontSize: '12px', padding: '8px' }}>
              <TranslateText i18nKey='fileUpload.extensionError' />
            </Typography>
          )}
        </div>
        {uploadDomain.ui.files.list.map((file) => (
          <Box
            sx={{
              padding: '16px 12px',
              background: 'rgba(25, 118, 210, 0.08)',
              borderRadius: '4px',
              margin: '12px 0px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>{file.name}</Typography>
              <Close onClick={() => uploadDomain.deleteFile()} sx={{ cursor: 'pointer' }} />
            </Box>
            <Typography sx={{ fontSize: '12px', color: 'rgba(255,255,255,0.6)' }}>
              <TranslateText i18nKey='fileUpload.fileInfo' options={{ ext: file.type.toUpperCase(), size: Math.ceil(file.size / 1024 / 1000) }} />
            </Typography>
          </Box>
        ))}
      </section>
    </React.Fragment>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  blueSpan: {
    color: '#1976D2',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
