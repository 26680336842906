import React, { Component, ErrorInfo } from 'react';

import ErrorPage from '../ErrorPage';
import { IErrorBoundaryProps, IErrorBoundaryState } from './IErrorBoundary';

class ErrorBoundaryApp extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any): IErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {}

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryApp;
