import { inject } from "../../../../../../../common/container/inject";
import { Store } from "../../../../../../../common/store/base/Store";
import { IRouterService, RouterServiceToken } from "../../../../../../../service/route/IRouterService";
import { IOAUTHService } from "../../../../../service/ouath/IOAUTHService";
import { RestOAUTHService } from "../../../../../service/ouath/RestOAUTHService";
import { HaveNotQueryOAUTHError } from "../errors/HaveNotQueryOAUTHError";
import { KnownErrorDuringOAUTHError } from "../errors/KnownErrorDuringOAUTHError";
import { IVKOAUTHRedirectPageDomain } from "./IVKOAUTHRedirectPageDomain";

const SUCCESS_QUERY_NAME = 'code'
const ERROR_QUERY_NAME = 'error'

export class VKOAUTHRedirectPageDomain extends Store implements  IVKOAUTHRedirectPageDomain  {
  constructor(
    protected oauthService: IOAUTHService = new RestOAUTHService(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private isProcessing = false,

) {
  super()
}

async boot(): Promise<void> {
  if (this.isProcessing) return;
  this.isProcessing = true;
  await this.catchErrorThroughAlert(this.processLogin);
  this.isProcessing = false;
}


protected processLogin = async () => {
  const authorizationCode = this.getParamsFromUrl();
  await this.oauthService.auth({ code: authorizationCode });
  this.redirectToUserPage();
}


  protected async catchErrorThroughAlert<ClbReturn>(clb: () => ClbReturn) {
    try {
      return await clb();
    } catch (e) {
      if (e instanceof Error || (e && typeof e === 'object' && e.hasOwnProperty('message') && 'message' in e)) {
        alert(e.message);

      }
      console.error(e);

      this.redirectToLogin()
    }
  }
  protected getParamsFromUrl(): string | never {
    const urlParams = new URLSearchParams(window.location.search);

    const authorizationCode = urlParams.get(SUCCESS_QUERY_NAME);
    if (authorizationCode) return authorizationCode;

    const error = urlParams.get(ERROR_QUERY_NAME);

    if (error) {
      throw new KnownErrorDuringOAUTHError(error)
    }

    throw new HaveNotQueryOAUTHError([SUCCESS_QUERY_NAME, ERROR_QUERY_NAME]);
  }

  private redirectToLogin = () => {
    this.router.goTo('/login');
  }

  private redirectToUserPage = () => {
    this.router.goTo('/');
  }
}
