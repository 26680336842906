import { AddBox, Close } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';

import { useI18n } from '../../../../../../../../application/language/useI18n';
import { ICommonProperties } from '../../../../../../../../common/properties/ICommonProperties';
import { PassageTimeAward } from './parts/PassageTimeAward';
import { QuestionPage } from './parts/QuestionPage';
import { Results } from './parts/Results';
import { IQuizDomain } from './store/IQuizDomain';
import { TranslateText } from '../../../../../../../../application/language/TranslateText';

export interface IQuizProperties extends ICommonProperties {
  domain: IQuizDomain;
}

export const Quiz = observer(({ domain }: IQuizProperties) => {
  const { translate } = useI18n();
  useEffect(() => {
    domain.boot();
  }, [domain]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ flex: '0.7', padding: '32px 24px 0px', height: '100%', overflowY: 'auto' }}>
        {domain.ui.content.value === 'question' && domain.ui.activeQuestion.value !== null && (
          <QuestionPage question={domain.ui.activeQuestion.value} />
        )}
        {domain.ui.content.value === 'awards' && <PassageTimeAward unitDomain={domain.unitDomain} />}
        {domain.ui.content.value === 'results' && <Results unitDomain={domain.unitDomain} />}
      </Box>
      <Divider light orientation="vertical" />
      <Box sx={{ padding: '8px 32px', maxWidth: '336px', width: '100%', flex: '0.3', overflow: 'hidden' }}>
        <Box sx={{ maxHeight: 'calc(100% - 250px)', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '9px' }}>
            <Typography variant="h6">{translate('phrases.questions')}</Typography>
            <AddBox sx={{ cursor: 'pointer' }} onClick={() => domain.addQuestion()} />
          </Box>

          <Box
            sx={{
              overflowY: 'auto',
              height: 'calc(100% - 250px - 32px)',
              display: 'flex',
              flexDirection: 'column',
              gap: '9px',
              marginBottom: '35px',
            }}
          >
            {domain.unitDomain.ui.unit.entity.quiz.questions.map((question, index) => (
              <Box
                key={index}
                onClick={() => {
                  domain.ui.content.setValue('question');
                  domain.ui.activeQuestion.setValue(question);
                }}
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                  background:
                    question.sortIndex === domain.ui.activeQuestion.value?.sortIndex ? 'rgba(0,0,0,0.25)' : '',
                  padding: '8px 12px',
                  marginBottom: '8px',
                  borderRadius: '8px',
                  '&:hover': { background: 'rgba(0,0,0,0.25)' },
                  '&:hover .question__delete': { visibility: 'visible' },
                }}
              >
                <Typography variant="body1">{`${translate('phrases.question')} ${question.sortIndex}`}</Typography>
                <Close
                  className="question__delete"
                  sx={{ visibility: 'hidden', cursor: 'pointer' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    domain.onDelete(index);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Typography variant="h6" sx={{ marginBottom: '9px' }}>
        {translate('phrases.settings')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '9px', marginBottom: '32px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '9px', marginBottom: '32px' }}>
            <Box
              onClick={() => {
                domain.ui.content.setValue('awards');
                domain.ui.activeQuestion.setValue(null as any);
              }}
              sx={{
                background: domain.ui.content.value === 'awards' ? ' rgba(25, 118, 210, 0.12)' : '',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px 12px',
                borderRadius: '8px',
                '&:hover': { background: ' rgba(25, 118, 210, 0.12)' },
              }}
            >
              <Typography variant="body1"><TranslateText i18nKey={'passingResults.completionTimeAndReward'} /></Typography>
            </Box>
            <Box
              onClick={() => {
                domain.ui.content.setValue('results');
                domain.ui.activeQuestion.setValue(null as any);
              }}
              sx={{
                background: domain.ui.content.value === 'results' ? ' rgba(25, 118, 210, 0.12)' : '',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '5px 12px',
                borderRadius: '8px',
                '&:hover': { background: ' rgba(25, 118, 210, 0.12)' },
              }}
            >
              <Typography variant="body1">{translate(`phrases.results`)}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
