import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';

export const extendedGenerateName = ({
  domain,
  reportName,
}: {
  domain: ApplicationDetailDomain;
  reportName: string;
}): string => {
  domain.getSpecificationsTextFieldsData()
  const specificationsTextFieldsValues = domain.ui.specificationsTextFieldsValues.list || []
  const shortedNameSpec = specificationsTextFieldsValues?.find(
    (field) => field.description?.toLocaleLowerCase() === 'b. Краткое название АС'.toLocaleLowerCase(),
  );
  return `${shortedNameSpec ? shortedNameSpec.name : domain.ui.application.entity.name}${reportName ? '.' + reportName : ''}`;
};

export const extendedGenerateWithMinorVersion = ({
  domain,
}: {
  domain: ApplicationDetailDomain;
}): string => {
  const fullVersionNumber = domain.ui.application.entity.versionNumber;
  const partVersionNumber = fullVersionNumber?.split('.').splice(0, 2).join('.');

  return `${partVersionNumber}`;
};

export const extendedGenerateMajorVersion = ({
  domain,
}: {
  domain: ApplicationDetailDomain;
}): string => {
  const fullVersionNumber = domain.ui.application.entity.versionNumber;
  const partVersionNumber = fullVersionNumber?.split('.').splice(0, 1).join('.');

  return `${partVersionNumber}`;
};

