import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { ITaskManagerServer } from '../../../../../../service/taskManager/server/ITaskManagerServer';
import { ITaskManagerServerSearch } from '../../../../../../service/taskManager/server/ITaskManagerServerSearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { ProjectLink } from '../../../../../design/link/ProjectLink';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { Button, IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { TaskManagerAdminDomain } from './TaskManagerAdminDomain';

export class TaskManagerAdminUI
  extends DataTableUI<ITaskManagerServer, ITaskManagerServerSearch>
  implements IDataTableUI<ITaskManagerServer, ITaskManagerServerSearch> {
  constructor(
    public domain: TaskManagerAdminDomain,
    rows: IDataTableRowsUI<ITaskManagerServer, any> | null,

  ) {
    super(domain, rows);
  }
  setDomainId(): string {
    return 'settings/taskManager/server';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        field: 'name',
        headerName: 'Название',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        field: 'Webhook',
        headerName: 'Webhook',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          //@ts-ignore
          const url = `/settings/taskManager/server/${params.id}/webhook/info`;
          return (
            <ProjectLink url={url}>
              {
                //@ts-ignore 
                params?.row?.webhookData?.value?.isHavePermissions ? 'Создан автоматически' : 'Ручное создание'}
            </ProjectLink>
          );
        },
      },
      {
        field: 'host',
        headerName: 'Хост',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          return (
            //@ts-ignore 
            <MultiLineText value={params?.row?.credentials.value.host} />);
        },
      },
      {
        field: 'Template',
        headerName: 'Шаблон соединения',
        flex: 1,
        type: 'string',
        renderCell: (params: { value?: string }) => {
          //@ts-ignore
          const url = `/settings/taskManager/server/${params.id}/template`;
          const isCanEditOrCreate = this.isCanCreate.value || this.isCanEdit.value
          return (
            <Button
              style={{ textDecoration: 'underline' }}
              onClick={() => { this.domain.router.goTo(url) }}
              disabled={!isCanEditOrCreate}
            >
              {
                //@ts-ignore 
                params?.row?.connectionTemplate?.settings ? 'Редактировать шаблон' : 'Создать шаблон'
              }
            </Button>
          );
        },
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                style={{ marginRight: '8px' }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
