import i18next from 'i18next';
import { ITranslationsKeys } from '../../locales/ITranslationsKeys';

export type ITranslateOptions = Record<string, string | number>;

export interface ILocalization {
  i18n: typeof i18next;
  translate(key: ITranslationsKeys, options?: ITranslateOptions): string;
  translateAnyway(key: string, options?: ITranslateOptions): string;
  changeLanguage(language: string): any;
  getCurrentLanguage: () => string
}
export const ILocalizationToken = Symbol.for('ILocalizationToken');
