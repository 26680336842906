import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Button, CssBaseline, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { TranslateText } from '../../../../application/language/TranslateText';
import { useI18n } from '../../../../application/language/useI18n';
import { inject } from '../../../../common/container/inject';
import { FormAutocomplete } from '../../../design/form/FormAutocomplete';
import { AdditionalSignInStrategy, IAdditionalSignInStrategy } from './SignInPageInjects';
import { ISignInPageDomain, SignInPageDomainToken } from './store/ISignInPageDomain';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  additionalVariants: {
    display: 'flex',
    position: 'relative',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  additionalLabel: {
    '&:before, &:after': {
      content: '""',
      position: 'relative',
      bottom: '50%',
      left: '0px',
      width: '100%',
      height: '1px',
      background: 'rgba(255, 255, 255, 0.23)',
    },
    display: 'flex',
    color: 'rgba(255, 255, 255, 0.23)',
    justifyContent: 'center',
    position: 'relative',
    gap: theme.spacing(1),
    alignItems: 'center'
  }
}));

export const SignInPage = observer(() => {
  const classes = useStyles();
  const [signInPageDomain] = useState<ISignInPageDomain>(inject(SignInPageDomainToken));
  const [Component] = useState<IAdditionalSignInStrategy>(inject(AdditionalSignInStrategy));

  const { translate } = useI18n();

  useEffect(() => {
    signInPageDomain.boot();
  }, [signInPageDomain]);

  return (
    <div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <TranslateText i18nKey="login.label" />
        </Typography>
        <form
          onSubmit={(event: any) => {
            event.preventDefault();
            signInPageDomain.login();
          }}
          className={classes.form}
        >
          <TextField
            id="email"
            label={translate('login.login')}
            variant="outlined"
            color="secondary"
            autoComplete="email"
            margin="normal"
            value={signInPageDomain.ui.loginField.value}
            onChange={(event: any) => {
              if (signInPageDomain.ui.loginField.value !== event.target.value) {
                signInPageDomain.ui.loginField.setValue(event.target.value);
              }
            }}
            disabled={signInPageDomain.ui.isLockForm.value}
            required
            fullWidth
            autoFocus
            error={signInPageDomain.ui.loginErrorMessage.value.length > 0}
            helperText={signInPageDomain.ui.loginErrorMessage.value}
          />
          <TextField
            id="password"
            label={translate('login.password')}
            variant="outlined"
            color="secondary"
            autoComplete="current-password"
            margin="normal"
            type="password"
            value={signInPageDomain.ui.passwordField.value}
            onChange={(event: any) => {
              if (signInPageDomain.ui.passwordField.value !== event.target.value) {
                signInPageDomain.ui.passwordField.setValue(event.target.value);
              }
            }}
            disabled={signInPageDomain.ui.isLockForm.value}
            required
            fullWidth
            autoFocus
            error={signInPageDomain.ui.passwordErrorMessage.value.length > 0}
            helperText={signInPageDomain.ui.passwordErrorMessage.value}
          />
          {signInPageDomain.ui.loginMethods.list.length > 1 && (
            <FormAutocomplete
              values={signInPageDomain.ui.loginMethods.list}
              onChangeSelect={(newValue: any) => {
                signInPageDomain.changeLoginMethod(newValue.value);
              }}
              key="loginMethods"
              selected={signInPageDomain.ui.loginMethodValue.value}
              label={translate('login.method')}
              size="medium"
              controlPadding={'16px 0px'}
            />
          )}

          <Button
            disabled={signInPageDomain.ui.isLockForm.value}
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            <TranslateText i18nKey="login.btnLabel" />
          </Button>

          {Component ? (
            <div className={classes.additionalVariants}>
              <div className={classes.additionalLabel}><span>или</span></div>
              {Component()}
            </div>
          ) : null}

        </form>
      </div>
    </div>
  );
});
