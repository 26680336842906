import { ICoreCRUDService } from '../coreCommon/common/service/ICoreCRUDService';
import { IFileStoreModel } from './IFileStoreModel';

export type IuploadImageResponse = {
  fileUrl: string;
  message: string;
}
export interface IFileStoreService extends ICoreCRUDService<IFileStoreModel> {
  uploadImage(data: any, fileType: FileType): Promise<IuploadImageResponse>;
  deleteByFile(fileName: string, fileType: FileType): Promise<any>;
  uploadImageWithData(data: any, fileType: FileType): Promise<any>;
}

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  DOC = 'doc',
}
