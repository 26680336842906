import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useI18n } from '../../../../../../../../../application/language/useI18n';
import { ITask, ITaskLanguage } from '../../../../../../../service/unit/interface/IUnitPractice';
import { PassingPracticeStart } from '../../../../passing/parts/practice/PassingPracticeStart';
import { PassingTask } from '../../../../passing/parts/practice/PassingTask';
import { IPassingPracticeDomain } from '../../../../passing/parts/practice/store/IPassingPracticeDomain';
import { PassingPracticeDomain } from '../../../../passing/parts/practice/store/PassingPracticeDomain';
import { LearningUnitPreviewPageContext } from '../../../store/LearningUnitPreviewPageContext';

export const PreviewPractice = observer(() => {
  const { unitPreviewDomain, setShowSidebar } = useContext(LearningUnitPreviewPageContext);
  const [practiceDomain] = useState<IPassingPracticeDomain>(new PassingPracticeDomain());
  const unit = unitPreviewDomain?.ui?.unit?.entity;
  const questionIndex = (unitPreviewDomain?.ui?.currentSubstep.value as number) || 0;
  const currentQuestion = unit?.practice?.tasks[questionIndex] as ITask;
  const { translate } = useI18n();

  const [lang, setLang] = useState<ITaskLanguage>();
  const classes = useStyles();

  useEffect(() => {
    setLang(undefined);
  }, [questionIndex]);

  const langChange = useCallback(
    (value: string) => {
      const lang = currentQuestion?.languages.find((item) => item.name === value);
      setLang(lang);
    },
    [currentQuestion?.languages],
  );

  if (!lang && !currentQuestion?.languages.length) {
    return (
      <div className={classes.errorContainer}>
        {/* @ts-ignore */}
        <PassingPracticeStart
          languages={currentQuestion?.languages.map((item) => item.name)}
          pickingLang={langChange}
        />
        <Chip color="error" label={translate('phrases.langNotChosen')} />
      </div>
    );
  }

  return (
    <div className={classes.practiceContainer}>
      {!lang ? (
        // @ts-ignore
        <PassingPracticeStart
          languages={currentQuestion?.languages.map((item) => item.name)}
          pickingLang={langChange}
        />
      ) : (
        // @ts-ignore
        <PassingTask
          task={currentQuestion}
          activeLang={lang}
          passingCodeEditorDomain={unitPreviewDomain?.passingCodeEditorDomain}
          rootDomain={practiceDomain}
          setShowSidebar={setShowSidebar}
          previewAnswer={{ result: 1 }}
        />
      )}
    </div>
  );
});

export const useStyles = makeStyles(() => ({
  practiceContainer: {
    width: '100%',
    '& #submitCodeAnswer': {
      width: '120px',
      '& label': { margin: 0 },
    },
    '& h6': {
      marginTop: '24px',
      marginLeft: '24px',
    },
    '& p': {
      marginLeft: '24px',
    },
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
