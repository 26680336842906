import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';

import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementSearch } from '../../../../../../service/requirement/entity/IRequirementSearch';
import { IVendorRequirementModel } from '../../../../../../service/requirement/vendor/IVendorRequirementModel';
import { IVendorEntityModel } from '../../../../../../service/vendor/entity/IVendorEntityModel';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { VendorRequirementRowsUI } from './rows/VendorRequirementRowsUI';
import { RichTextArea } from '../../../../../design/RichTextEditor/RichTextEditor';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';

export class VendorRequirementAdminUI
  extends DataTableUI<IVendorRequirementModel, IRequirementSearch>
  implements IDataTableUI<IVendorRequirementModel, IRequirementSearch> {
  constructor(
    public domain: IDataTableDomain<IVendorRequirementModel, IRequirementSearch>,
    rows: IDataTableRowsUI<IVendorRequirementModel, IRequirementSearch> | null,

    public vendorEntity: IEntityListStore<IVendorEntityModel> = injectEntityList<IVendorEntityModel>([]),
    public requirementEntity: IEntityListStore<IRequirementModel> = injectEntityList<IRequirementModel>([]),
  ) {
    super(domain, rows);
    this.rows = rows || new VendorRequirementRowsUI(this);
  }

  setDomainId(): string {
    return 'settings/vendor/requirement';
  }
  async getColumns(): Promise<GridColumns> {
    const entityList = this.domain.ui.rows.entities.list

    this.setActionColumn()
    return [
      {
        headerName: 'Короткое название',
        field: 'shortName',
        flex: 0.5,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value} />;
        },
        sortComparator: (a, b) => {
          const partsA = a.split(' ');
          const partsB = b.split(' ');

          const numsA = partsA[0].split('.').map(Number);
          const numsB = partsB[0].split('.').map(Number);

          for (let i = 0; i < Math.max(numsA.length, numsB.length); i++) {
            const numA = numsA[i] || 0;
            const numB = numsB[i] || 0;

            if (numA !== numB) {
              return numA - numB;
            }
          }

          const textA = partsA[1] || '';
          const textB = partsB[1] || '';
          return textA.localeCompare(textB);
        }
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params) => {
          const cureentRawEntity = entityList.find(item => item.id === params.id)

          return <RichTextArea data={params.value || ''} dataWithStyles={cureentRawEntity?.descriptionWithStyles || ''} />
        },
      },
      {
        headerName: 'Категория каталога требований',
        field: 'vendorId',
        flex: 0.5,
        valueGetter: (params) => {
          const categoryName = this.domain.ui.rows.getFieldValueAsString('vendorId', params.value);
          return categoryName;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('vendorId', params.row?.vendorId);
          return (
            <DataTableRowDependency
              onClick={() => {
                this.domain.onLinkAction(params.row.vendorId, '/settings/vendor/category');
              }}
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        headerName: 'Требования',
        field: 'requirementsIds',
        flex: 0.5,
        valueGetter: (params) => {
          const names = this.domain.ui.rows.getFieldValueAsString('requirementsIds', params.value);
          return names;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies(
            'requirementsIds',
            params.row.requirementsIds,
          );
          return (
            <DataTableRowDependency
              getTextValue={(item: any) => item.shortName}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        headerName: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        flex: 0.3,
        align: 'center',
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
        headerAlign: 'left',
        align: 'left'
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                style={{ marginRight: '8px' }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
