import { Theme } from '@emotion/react';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { IServiceModel } from '../../../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../../../service/common/search/ISearchRequestData';
import { useButtonStyles } from '../../../../design/button/ButtonStyle';
import { Colors } from '../../../../design/color/Colors';
import { IDataTableDomain } from '../../store/IDataTableDomain';
import TableHeaderSearchBar from './TableHeaderSearchBar';
import { useI18n } from '../../../../../application/language/useI18n';
export interface TableHeaderProperties {
  tableDomain: IDataTableDomain<IServiceModel, ISearchRequestData>;
}

const TableHeader = observer((
  { tableDomain }: TableHeaderProperties
) => {
  const { translate } = useI18n()
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const tableAddButtonName = tableDomain.ui.tableAddButtonName?.value || translate('phrases.add')
  return (
    <>
      <div className={classes.tableToolBar}>
        <Toolbar className={classes.actions}>
          <div className={classes.tableName}>
            <h3 className={classes.tableNameMain}>{tableDomain.ui.tableTitle?.value || ''}</h3>
            <h1 className={classes.tableNameType}>{tableDomain.ui.tableSubtitle?.value || ''}</h1>
          </div>
          <div className={classes.buttons}>
            {tableDomain.ui.isCanCreate.value
              && <LoadingButton
                className={buttonClasses.button}
                style={{
                  backgroundColor: `${Colors.greenButtonOpacity('0.7')} `,
                  marginBottom: '20px',
                  marginRight: '10px',
                }}
                variant="outlined"
                startIcon={<Add />}
                color="success"
                loading={tableDomain.drawerDomain?.isSaving.entity}
                onClick={tableDomain.onHandleAdd}>
                {tableAddButtonName} 
              </LoadingButton>
            }
          </div>
        </Toolbar>
      </div>
      <TableHeaderSearchBar tableDomain={tableDomain} />
    </>
  )
})

export default TableHeader


const useStyles = makeStyles((theme: Theme) => ({
  tableToolBar: {
    padding: '20px 10px 0px 15px',
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
    zIndex: 999,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: window.innerWidth - 239
  },
  search: {
    alignSelf: 'flex-end',
  },
  actions: {
    padding: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  button: {
    width: '220px',
    height: 'fit-content',
    marginRight: '10px',
    color: '#f4f4f4',
  },
  add: {
    marginBottom: '20px',
    marginRight: '10px',
  },
  delete: {
    backgroundColor: `${Colors.redButtonOpacity('0.6')} `,
    marginBottom: '20px',
  },
  buttons: {
    display: 'flex',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  saveButton: {
    height: 'fit-content',
    marginRight: '10px',
  },
  tableName: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
  },
  tableNameMain: {
    fontWeight: 100,
    margin: 0,
  },
  tableNameType: {
    fontSize: 44,
    margin: 0,
    fontWeight: 100,
  },
  filterValue: {
    justifyContent: 'center',
    height: '32px',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    borderRadius: '16px',
    marginRight: 5,
  },
  filterValueBlock: {
    width: '100%',
    display: 'flex',
    marginBottom: 10,
  }
}));