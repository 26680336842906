import { Divider, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { useI18n } from '../../../../../../../application/language/useI18n';
import { ICodeContextMenu } from './ICodeContextMenu';

export const CodeContextMenu = observer(
  ({ open, onClose, event, newFolder, currentFile, newFile, onDelete, onRename, toggleQuestion }: ICodeContextMenu) => {
    const { translate } = useI18n();
    return (
      <Menu
        open={open}
        onClose={onClose}
        anchorReference="anchorPosition"
        //@ts-ignore

        anchorPosition={open === true ? { top: event?.clientY, left: event?.clientX } : undefined}
      >
        <MenuItem
          disabled={!currentFile.isQuestion}
          onClick={() => {
            toggleQuestion(currentFile);
            onClose();
          }}
        >
          {translate('codeEditor.removeQuestion')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onRename(currentFile)}>{translate('codeEditor.rename')}</MenuItem>
        <MenuItem>{translate('phrases.createCopy')}</MenuItem>
        <MenuItem onClick={() => onDelete(currentFile.id)}>{translate('phrases.delete')}</MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            newFile(currentFile);
          }}
        >
          {translate('phrases.newFile')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            newFolder(currentFile);
          }}
        >
          {translate('phrases.newFolder')}
        </MenuItem>
      </Menu>
    );
  },
);
