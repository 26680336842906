import { ApplicationAdditionalRoutes } from "../../application/ApplicationAdditionalRoutes";
import { IContainer } from "../../common/container/IContainer";
import { AdditionalSignInStrategy } from '../../view/user/page/login/SignInPageInjects';
import { IExtension } from "../interface/IExtension";
import { VKRouter } from './router/VKRouter';
import { VKConfigManager } from "./service/config/VKConfigManager";
import { VKAOUTHButton } from "./view/user/page/login/VKAOUTHButton";
export default class VKExtension implements IExtension {
  async loadExtension(container: IContainer): Promise<void> {
    await VKConfigManager.loadConfigFromBackend();
    container = this.addOAUTHButton(container);
    container = this.addVKRedirectAuthToRouter(container);
  }

  protected addOAUTHButton(container: IContainer) {
    container.set(AdditionalSignInStrategy, () => VKAOUTHButton)

    return container;
  }

  protected addVKRedirectAuthToRouter(container: IContainer) {
    let routerExtension = container.get(ApplicationAdditionalRoutes);
    routerExtension.push(...VKRouter);
    container.set(ApplicationAdditionalRoutes, routerExtension);

    return container;
  }
}