import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { Dispatch } from 'react';

import { useI18n } from '../../../../../../../../../../../application/language/useI18n';

interface Props {
  currentTab?: string;
  setCurrentTab: Dispatch<React.SetStateAction<string>>;
}

export const PreviewFinalResultsTabs = ({ currentTab, setCurrentTab }: Props) => {
  const { translate } = useI18n();
  return (
    <Tabs value={currentTab} onChange={(_, n) => setCurrentTab(n)}>
      <Tab label={translate('passing.success')} value={'success'} />
      <Tab label={translate('passing.neutral')} value={'neutral'} />
      <Tab label={translate('passing.failure')} value={'failure'} />
    </Tabs>
  );
};
